import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import settingReducer from "./settingSlice";
import provinceReducer from "./provinceSlice";
import detaiDamReducer from "./detailDamSlice";
import damReducer from "./damSlice";
import { useDispatch } from "react-redux";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    setting: settingReducer,
    province: provinceReducer,
    detailDam: detaiDamReducer,
    dam: damReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
