import { Select, SelectProps } from "antd";

function SelectWrap(props: SelectProps) {
  return (
    <Select
      showSearch
      allowClear
      filterOption={(input, option) =>
        ((option?.label ?? "") as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      {...props}
    />
  );
}

export default SelectWrap;
