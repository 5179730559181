import axios from "axios";
import {
  AuthApi,
  CustomersApi,
  Configuration,
  RolesApi,
  PermissionsApi,
  CompaniesApi,
  DamsApi,
  ProvincesApi,
  DetailDamsApi,
  AssetsApi,
  ApiMigrationApi,
} from "./client-axios";

const config = new Configuration({
  basePath: process.env.REACT_APP_API_URL,
  accessToken: localStorage.getItem("token") || undefined,
});
export const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const path = window.location.pathname.split("/");
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      if (path[1] == "admin") window.location.href = "/admin/signin";
      else window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

const authApi = new AuthApi(config, undefined, axiosInstance);
const customerApi = new CustomersApi(config, undefined, axiosInstance);
const roleApi = new RolesApi(config, undefined, axiosInstance);
const permissionApi = new PermissionsApi(config, undefined, axiosInstance);
const companyApi = new CompaniesApi(config, undefined, axiosInstance);
const damApi = new DamsApi(config, undefined, axiosInstance);
const provinceApi = new ProvincesApi(config, undefined, axiosInstance);
const detailDapApi = new DetailDamsApi(config, undefined, axiosInstance);
const assetApi = new AssetsApi(config, undefined, axiosInstance);
const updateMigrationApi = new ApiMigrationApi(config, undefined, axiosInstance);

export {
  authApi,
  customerApi,
  roleApi,
  permissionApi,
  companyApi,
  damApi,
  provinceApi,
  assetApi,
  detailDapApi,
  updateMigrationApi,
};
