import React, { LazyExoticComponent, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { SuspenseWrapper } from "../../components/loading/SuspenseWrap";

const Customer = React.lazy(() => import("."));
const SignInCustomer = React.lazy(() => import("./auth/SignInCustomer"));
const ChooseDam = React.lazy(() => import("./dam/ChooseDam"));
const ProjectCapital = React.lazy(() => import("./capitalValue/ProjectCapitalIndex"));
const LossLife = React.lazy(() => import("./capitalValue/LossLifeIndex"));
const EconomicIndex = React.lazy(() => import("./capitalValue/EconomicIndex"));
const HazardIndex = React.lazy(() => import("./capitalValue/HazardIndex"));
const Dam = React.lazy(() => import("./dam"));
const Asset = React.lazy(() => import("./dam/asset"));
const Export = React.lazy(() => import("./dam/Export"));
const Map = React.lazy(() => import("./dam/Map"));
const StaticResponseEMBK = React.lazy(() => import("./step1/StaticResponseEMBK"));
const StaticResponseCONC = React.lazy(() => import("./step1/StaticResponseCONC"));
const HydrologicResponse = React.lazy(() => import("./step1/HydrologicResponse"));
const SeismicResponseEMBK = React.lazy(() => import("./step1/SeismicResponseEMBK"));
const SeismicResponseCONC = React.lazy(() => import("./step1/SeismicResponseCONC"));
const SafetyPlan = React.lazy(() => import("./step1/SafetyPlan"));
const RiskScreening = React.lazy(() => import("./step1/RiskScreening"));
const Danger = React.lazy(() => import("./dam/Danger"));
const DasboardCustomer = React.lazy(() => import("./dam/Dasboard/index"));
export const CustomerRoutes = () => (
  <Routes>
    <Route path="/signin" element={<SignInCustomer />} />
    <Route path="choose-dam" element={<ChooseDam />} />
    <Route path="" element={<Customer />}>
      <Route path="dashboard" element={<SuspenseWrapper component={<DasboardCustomer />} />} />
      <Route path="" element={<SuspenseWrapper component={<Dam />} />} />
      <Route path="asset" element={<SuspenseWrapper component={<Asset />} />} />
      <Route path="capitalvalue" element={<SuspenseWrapper component={<ProjectCapital />} />} />
      <Route path="losslife" element={<SuspenseWrapper component={<LossLife />} />} />
      <Route path="economic" element={<SuspenseWrapper component={<EconomicIndex />} />} />
      <Route path="hazard" element={<SuspenseWrapper component={<HazardIndex />} />} />
      <Route path="export" element={<SuspenseWrapper component={<Export />} />} />

      <Route path="static-response-embk" element={<SuspenseWrapper component={<StaticResponseEMBK />} />} />
      <Route path="static-response-conc" element={<SuspenseWrapper component={<StaticResponseCONC />} />} />
      <Route path="hydrologic-response" element={<SuspenseWrapper component={<HydrologicResponse />} />} />
      <Route path="seismic-response-embk" element={<SuspenseWrapper component={<SeismicResponseEMBK />} />} />
      <Route path="seismic-response-conc" element={<SuspenseWrapper component={<SeismicResponseCONC />} />} />
      <Route path="safety-plan" element={<SuspenseWrapper component={<SafetyPlan />} />} />
      <Route path="risk-screening" element={<SuspenseWrapper component={<RiskScreening />} />} />
      <Route path="danger" element={<SuspenseWrapper component={<Danger />} />} />
      <Route path="map" element={<SuspenseWrapper component={<Map />} />} />
    </Route>
  </Routes>
);
