import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { axiosInstance } from "../apis";
import { Administrator, Customer, Dam, LoginResponseDto } from "../apis/client-axios";
import { cloneDeep } from "lodash";
import { RoleCode } from "../constants/role.enum";

export interface AuthState {
  loading: boolean;
  error: string;
  homeRoute: string;
  adminLoginRoute: string;
  customerLoginRoute: string;
  userType?: "administrator" | "customer";
  authUser?: Administrator | Customer;
  selectedDamId?: number;
  selectedDam?: Dam;
  selectedBaseDate?: Date;
  selectedDetailDamId?: number;
}

const initState: AuthState = {
  loading: false,
  error: "",
  homeRoute: "/",
  adminLoginRoute: "/admin/signin",
  customerLoginRoute: "/main/signin",
};

const resetLocalstorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("selectedDamId");
  localStorage.removeItem("authUser");
  localStorage.removeItem("userType");
  localStorage.removeItem("selectedDetailDamId");
  localStorage.removeItem("selectedBaseDate");
};

export const isSuperAdmin = (authUser: Administrator | Customer | undefined) => {
  return authUser?.user?.roles.map((x) => x.code).includes(RoleCode.SuperAdminRole);
};

export const authSlice = createSlice({
  name: "auth",
  initialState: (): AuthState => {
    let init: AuthState = cloneDeep(initState);

    const authUser = localStorage.getItem("authUser");
    if (authUser) {
      const parseAuthUser = JSON.parse(authUser);
      init.authUser = parseAuthUser;

      if ((parseAuthUser as Customer).damId) {
        init.selectedDamId = (parseAuthUser as Customer).damId;
      } else {
        const selectedDamId = localStorage.getItem("selectedDamId");
        if (selectedDamId) {
          init.selectedDamId = Number(selectedDamId);
        }
      }

      const selectedDetailDamId = localStorage.getItem("selectedDetailDamId");
      if (selectedDetailDamId) {
        init.selectedDetailDamId = +selectedDetailDamId;
      }

      const userType = localStorage.getItem("userType") as any;
      init.userType = userType;
    }
    return init;
  },
  reducers: {
    login: (state, action: PayloadAction<LoginResponseDto & { userType: "administrator" | "customer" }>) => {
      resetLocalstorage();
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("userType", action.payload.userType);
      state.userType = action.payload.userType;
      axiosInstance.defaults.headers.Authorization = action.payload.token ? `Bearer ${action.payload.token}` : "";
    },
    updateMe: (state, action: PayloadAction<Customer | Administrator>) => {
      state.authUser = action.payload;
      if ((state.authUser as Customer)?.damId) {
        state.selectedDamId = (state.authUser as Customer).damId;
      }
      localStorage.setItem("authUser", JSON.stringify(action.payload));
    },
    setSelectedDamId: (state, action: PayloadAction<number>) => {
      localStorage.setItem("selectedDamId", action.payload.toString());
      state.selectedDamId = action.payload;
    },
    setSelectedBaseDate: (state, action: PayloadAction<Date>) => {
      localStorage.setItem("selectedBaseDate", action.payload.toString());
      state.selectedBaseDate = action.payload;
    },
    setSelectedDetailDamId: (state, action: PayloadAction<number>) => {
      localStorage.setItem("selectedDetailDamId", action.payload.toString());
      state.selectedDetailDamId = action.payload;
    },
    updateDam: (state, action: PayloadAction<Dam>) => {
      state.selectedDam = action.payload;
    },
    logout: (state) => {
      state = initState;
      resetLocalstorage();
      window.location.href = state.homeRoute;
    },
  },
});

export const { logout, login, updateMe, setSelectedDamId, setSelectedBaseDate, updateDam, setSelectedDetailDamId } =
  authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
