import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { axiosInstance, provinceApi } from "../apis";
import { Province } from "../apis/client-axios";

export interface ProvinceState {
  provinces?: Province[];
  total?: number;
}

const initialState: ProvinceState = {
  provinces: [],
  total: 0,
};

export const fetchProvinces = createAsyncThunk("provinces/fetchAll", async () => {
  const response = await provinceApi.provinceControllerGet();
  return response.data;
});

export const provinceSlice = createSlice({
  name: "province",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProvinces.fulfilled, (state, action) => {
      state.provinces = action.payload.content;
      state.total = action.payload.total;
    });
  },
});

export const selectProvince = (state: RootState) => state.province;
export default provinceSlice.reducer;
