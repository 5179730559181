import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AdminRoutes } from "./routes/admin/adminRoutes";
import { CustomerRoutes } from "./routes/customer/customerRoutes";
import React from "react";
import NotFoundPage from "./components/NotFoundPage";
const Home = React.lazy(() => import("./routes/Home"));

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="main/*" element={<CustomerRoutes />} />
        <Route path="admin/*" element={<AdminRoutes />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesApp;
