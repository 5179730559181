import { Button, ButtonProps, Pagination, Row, Table } from "antd";
import { LeftOutlined } from "@ant-design/icons";

function BackButton(props: ButtonProps) {
  return (
    <Button icon={<LeftOutlined />} type="default" {...props}>
      {props.children || "Quay lại"}
    </Button>
  );
}

export default BackButton;
