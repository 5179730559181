import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { damApi } from "../apis";
import { Dam, DamSearchFilter } from "../apis/client-axios";

export interface damState {
  dam?: Dam[];
  total?: number;
}

const initialState: damState = {
  dam: [],
  total: 0,
};

export const fetchDam = createAsyncThunk("provinces/dam", async (filer: DamSearchFilter) => {
  const response = await damApi.damControllerGetAllDamSortNguyCoRuiRo(filer);
  return response.data;
});

export const damSlice = createSlice({
  name: "dam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDam.fulfilled, (state, action) => {
      state.dam = action.payload;
    });
  },
});

export const selectDam = (state: RootState) => state.dam;
export default damSlice.reducer;
