import { Button, ButtonProps, Pagination, Row, Table } from "antd";
import { SaveOutlined } from "@ant-design/icons";

function SaveButton(props: ButtonProps) {
  return (
    <Button icon={<SaveOutlined />} type="primary" htmlType="submit" {...props}>
      {props.children || "Lưu"}
    </Button>
  );
}

export default SaveButton;
