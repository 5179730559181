/* tslint:disable */
/* eslint-disable */
/**
 * PROJECT_NAME
 * Api for development - v1.0
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddAssetsDamDto
 */
export interface AddAssetsDamDto {
    /**
     * 
     * @type {Array<IdDto>}
     * @memberof AddAssetsDamDto
     */
    'assets': Array<IdDto>;
}
/**
 * 
 * @export
 * @interface Administrator
 */
export interface Administrator {
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'emailAddress': string;
    /**
     * 
     * @type {User}
     * @memberof Administrator
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof Administrator
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Administrator
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Administrator
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Administrator
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'type': AssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'mimeType': string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'fileSize': number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'preview': string;
    /**
     * 
     * @type {AssetFocalPoint}
     * @memberof Asset
     */
    'focalPoint': AssetFocalPoint;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'parentId': number;
    /**
     * 
     * @type {Array<Asset>}
     * @memberof Asset
     */
    'children': Array<Asset>;
    /**
     * 
     * @type {Asset}
     * @memberof Asset
     */
    'parent': Asset;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'deletedAt': string;
}

export const AssetTypeEnum = {
    Image: 'IMAGE',
    Video: 'VIDEO',
    Binary: 'BINARY',
    Folder: 'FOLDER'
} as const;

export type AssetTypeEnum = typeof AssetTypeEnum[keyof typeof AssetTypeEnum];

/**
 * 
 * @export
 * @interface AssetFocalPoint
 */
export interface AssetFocalPoint {
    /**
     * 
     * @type {number}
     * @memberof AssetFocalPoint
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetFocalPoint
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface AuthenticationMethod
 */
export interface AuthenticationMethod {
    /**
     * 
     * @type {User}
     * @memberof AuthenticationMethod
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationMethod
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationMethod
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationMethod
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationMethod
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationMethod
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationMethod
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ChuanBiKhanCap
 */
export interface ChuanBiKhanCap {
    /**
     * 
     * @type {boolean}
     * @memberof ChuanBiKhanCap
     */
    'cacKeHoachChuanBi': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChuanBiKhanCap
     */
    'eapKhongDuocThucHien': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChuanBiKhanCap
     */
    'khongCoGiaoDuc': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChuanBiKhanCap
     */
    'cacCoCheCanhBao': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChuanBiKhanCap
     */
    'quyTrinhPhoiHop': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChuanBiKhanCap
     */
    'khongCoSuPhoiHop': boolean;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'taxCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'address'?: string;
    /**
     * 
     * @type {Array<Dam>}
     * @memberof Company
     */
    'dams': Array<Dam>;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof Company
     */
    'customers': Array<Customer>;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CompanyControllerGet200Response
 */
export interface CompanyControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof CompanyControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Company>}
     * @memberof CompanyControllerGet200Response
     */
    'content'?: Array<Company>;
}
/**
 * 
 * @export
 * @interface CompanyControllerGet200ResponseAllOf
 */
export interface CompanyControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Company>}
     * @memberof CompanyControllerGet200ResponseAllOf
     */
    'content'?: Array<Company>;
}
/**
 * 
 * @export
 * @interface CongTrinhXa
 */
export interface CongTrinhXa {
    /**
     * 
     * @type {boolean}
     * @memberof CongTrinhXa
     */
    'khongCoTangLocHoacVungLocXungQuangOngDan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CongTrinhXa
     */
    'ongDanXaDatSauTrenDatHoacDa': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CongTrinhXa
     */
    'duongOngXaLamBangVatLieuDeBiAnMon': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CongTrinhXa
     */
    'hinhDangOngDanXau': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CongTrinhXa
     */
    'cacVetNutHoTrenOngDanNuoc': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CongTrinhXa
     */
    'ongDanDuocDatTrenDatHoacDaCoDoNenNoCao': boolean;
}
/**
 * 
 * @export
 * @interface CreateCompanyDto
 */
export interface CreateCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'taxCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyDto
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface CreateDamDto
 */
export interface CreateDamDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDamDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDamDto
     */
    'provinceId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateDamDto
     */
    'companyId': number;
}
/**
 * 
 * @export
 * @interface CreateDetailDamDto
 */
export interface CreateDetailDamDto {
    /**
     * 
     * @type {number}
     * @memberof CreateDetailDamDto
     */
    'damId': number;
    /**
     * 
     * @type {DacDiemLuuVuc}
     * @memberof CreateDetailDamDto
     */
    'dacDiemLuuVuc'?: DacDiemLuuVuc;
    /**
     * 
     * @type {HoChua}
     * @memberof CreateDetailDamDto
     */
    'hoChua'?: HoChua;
    /**
     * 
     * @type {Array<DapChinhPhu>}
     * @memberof CreateDetailDamDto
     */
    'dapChinhPhus'?: Array<DapChinhPhu>;
    /**
     * 
     * @type {Array<DapTran>}
     * @memberof CreateDetailDamDto
     */
    'dapTrans'?: Array<DapTran>;
    /**
     * 
     * @type {DapChinhPhu}
     * @memberof CreateDetailDamDto
     */
    'dapChinh'?: DapChinhPhu;
    /**
     * 
     * @type {Array<CuaLayNuoc>}
     * @memberof CreateDetailDamDto
     */
    'cuaLayNuocs'?: Array<CuaLayNuoc>;
    /**
     * 
     * @type {NhaMay}
     * @memberof CreateDetailDamDto
     */
    'nhaMay'?: NhaMay;
    /**
     * 
     * @type {DanSoNguyHiem}
     * @memberof CreateDetailDamDto
     */
    'danSoNguyHiem'?: DanSoNguyHiem;
    /**
     * 
     * @type {string}
     * @memberof CreateDetailDamDto
     */
    'basedate': string;
    /**
     * 
     * @type {MucDoNghiemTrongDat}
     * @memberof CreateDetailDamDto
     */
    'datNongNghiep'?: MucDoNghiemTrongDat;
    /**
     * 
     * @type {MucDoNghiemTrongDat}
     * @memberof CreateDetailDamDto
     */
    'datDoThi'?: MucDoNghiemTrongDat;
    /**
     * 
     * @type {string}
     * @memberof CreateDetailDamDto
     */
    'luuYGiaTriVon'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDetailDamDto
     */
    'luuYGiaTriKinhTe'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDetailDamDto
     */
    'luuYThietHaiNhanMang'?: string;
    /**
     * 
     * @type {StaticResponseEMBK}
     * @memberof CreateDetailDamDto
     */
    'staticResponseEMBK'?: StaticResponseEMBK;
    /**
     * 
     * @type {StaticResponseCONC}
     * @memberof CreateDetailDamDto
     */
    'staticResponseCONC'?: StaticResponseCONC;
    /**
     * 
     * @type {HydrologicResponse}
     * @memberof CreateDetailDamDto
     */
    'hydrologicResponse'?: HydrologicResponse;
    /**
     * 
     * @type {SeismicResponseEMBK}
     * @memberof CreateDetailDamDto
     */
    'seismicResponseEMBK'?: SeismicResponseEMBK;
    /**
     * 
     * @type {SeismicResponseCONC}
     * @memberof CreateDetailDamDto
     */
    'seismicResponseCONC'?: SeismicResponseCONC;
    /**
     * 
     * @type {SafetyPlan}
     * @memberof CreateDetailDamDto
     */
    'safetyPlan'?: SafetyPlan;
    /**
     * 
     * @type {number}
     * @memberof CreateDetailDamDto
     */
    'giaTriXayLapDap'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDetailDamDto
     */
    'giaTriXayLapNhaMay'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDetailDamDto
     */
    'mapDoSauNgapNgayNangId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDetailDamDto
     */
    'mapMucDoNghiemTrongNgayNangId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDetailDamDto
     */
    'mapThoiGianLuToiNgayNangId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDetailDamDto
     */
    'nguyCoRuiRo'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateDetailDamDto
     */
    'ruiRoSangLoc'?: number;
}
/**
 * 
 * @export
 * @interface CreateFolderDto
 */
export interface CreateFolderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFolderDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateFolderDto
     */
    'assetFolderId'?: number;
}
/**
 * 
 * @export
 * @interface CreateRoleDto
 */
export interface CreateRoleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleDto
     */
    'code': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRoleDto
     */
    'permissions': Array<string>;
}
/**
 * 
 * @export
 * @interface CuaLayNuoc
 */
export interface CuaLayNuoc {
    /**
     * 
     * @type {string}
     * @memberof CuaLayNuoc
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CuaLayNuoc
     */
    'caoDoNguongCuaLayNuoc': number;
    /**
     * 
     * @type {string}
     * @memberof CuaLayNuoc
     */
    'kichThuocCuaVan': string;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'emailAddress': string;
    /**
     * 
     * @type {User}
     * @memberof Customer
     */
    'user'?: User;
    /**
     * 
     * @type {Asset}
     * @memberof Customer
     */
    'avatar'?: Asset;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'tempPassword'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    'isCenter': boolean;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'companyId': number;
    /**
     * 
     * @type {Company}
     * @memberof Customer
     */
    'company': Company;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'provinceId': number;
    /**
     * 
     * @type {Province}
     * @memberof Customer
     */
    'province': Province;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'damId': number;
    /**
     * 
     * @type {Dam}
     * @memberof Customer
     */
    'dam': Dam;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface CustomerControllerGet200Response
 */
export interface CustomerControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof CustomerControllerGet200Response
     */
    'content'?: Array<Customer>;
}
/**
 * 
 * @export
 * @interface CustomerControllerGet200ResponseAllOf
 */
export interface CustomerControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Customer>}
     * @memberof CustomerControllerGet200ResponseAllOf
     */
    'content'?: Array<Customer>;
}
/**
 * 
 * @export
 * @interface DacDiemLuuVuc
 */
export interface DacDiemLuuVuc {
    /**
     * 
     * @type {number}
     * @memberof DacDiemLuuVuc
     */
    'dienTichLuuVuc'?: number;
    /**
     * 
     * @type {number}
     * @memberof DacDiemLuuVuc
     */
    'luongMuaTrungBinhNam'?: number;
    /**
     * 
     * @type {number}
     * @memberof DacDiemLuuVuc
     */
    'luuLuongBinhQuan'?: number;
    /**
     * 
     * @type {number}
     * @memberof DacDiemLuuVuc
     */
    'dinhLuThietKe'?: number;
    /**
     * 
     * @type {number}
     * @memberof DacDiemLuuVuc
     */
    'dinhLuKiemTra'?: number;
    /**
     * 
     * @type {number}
     * @memberof DacDiemLuuVuc
     */
    'dungTichChongLu'?: number;
    /**
     * 
     * @type {number}
     * @memberof DacDiemLuuVuc
     */
    'luuLuongDongChayLu100Nam'?: number;
    /**
     * 
     * @type {number}
     * @memberof DacDiemLuuVuc
     */
    'luuLuongDinhLuChuKy100Nam'?: number;
    /**
     * 
     * @type {number}
     * @memberof DacDiemLuuVuc
     */
    'luuLuongTranThietKe'?: number;
}
/**
 * 
 * @export
 * @interface Dam
 */
export interface Dam {
    /**
     * 
     * @type {string}
     * @memberof Dam
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Dam
     */
    'provinceId': number;
    /**
     * 
     * @type {Province}
     * @memberof Dam
     */
    'province': Province;
    /**
     * 
     * @type {number}
     * @memberof Dam
     */
    'companyId': number;
    /**
     * 
     * @type {Company}
     * @memberof Dam
     */
    'company': Company;
    /**
     * 
     * @type {Array<DetailDam>}
     * @memberof Dam
     */
    'detailDams': Array<DetailDam>;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof Dam
     */
    'customers': Array<Customer>;
    /**
     * 
     * @type {number}
     * @memberof Dam
     */
    'assetFolderId'?: number;
    /**
     * 
     * @type {Asset}
     * @memberof Dam
     */
    'assetFolder'?: Asset;
    /**
     * 
     * @type {number}
     * @memberof Dam
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Dam
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Dam
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Dam
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Dam
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Dam
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface DamControllerGet200Response
 */
export interface DamControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof DamControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof DamControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof DamControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Dam>}
     * @memberof DamControllerGet200Response
     */
    'content'?: Array<Dam>;
}
/**
 * 
 * @export
 * @interface DamControllerGet200ResponseAllOf
 */
export interface DamControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Dam>}
     * @memberof DamControllerGet200ResponseAllOf
     */
    'content'?: Array<Dam>;
}
/**
 * 
 * @export
 * @interface DamSearchFilter
 */
export interface DamSearchFilter {
    /**
     * 
     * @type {number}
     * @memberof DamSearchFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamSearchFilter
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamSearchFilter
     */
    'sort'?: string;
    /**
     * 
     * @type {string}
     * @memberof DamSearchFilter
     */
    'fullTextSearch'?: string;
    /**
     * 
     * @type {number}
     * @memberof DamSearchFilter
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DamSearchFilter
     */
    'provinceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DamSearchFilter
     */
    'type'?: DamSearchFilterTypeEnum;
}

export const DamSearchFilterTypeEnum = {
    Ruiro: 'RUIRO',
    Nguyco: 'NGUYCO'
} as const;

export type DamSearchFilterTypeEnum = typeof DamSearchFilterTypeEnum[keyof typeof DamSearchFilterTypeEnum];

/**
 * 
 * @export
 * @interface DanSoNguyHiem
 */
export interface DanSoNguyHiem {
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'soDanCuGapNguyHiemH1': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'soDanCuGapNguyHiemH2': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'soDanCuGapNguyHiemH3': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'soDanCuGapNguyHiemH4': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'soDanCuGapNguyHiemH5': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'soDanCuGapNguyHiemH6': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'thoiGianLuToi': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'soDanCuGapNguyHiemNgayLuH1': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'soDanCuGapNguyHiemNgayLuH2': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'soDanCuGapNguyHiemNgayLuH3': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'soDanCuGapNguyHiemNgayLuH4': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'soDanCuGapNguyHiemNgayLuH5': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'soDanCuGapNguyHiemNgayLuH6': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'thoiGianLuToiNgayLu': number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'suCoNgayNangTongDanSoAfte'?: number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'suCoNgayNangChiSoThietHaiAfter'?: number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'suCoLuLutTongDanSoAfter'?: number;
    /**
     * 
     * @type {number}
     * @memberof DanSoNguyHiem
     */
    'suCoLuLutChiSoThietHaiAfter'?: number;
}
/**
 * 
 * @export
 * @interface DapChinhPhu
 */
export interface DapChinhPhu {
    /**
     * 
     * @type {string}
     * @memberof DapChinhPhu
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof DapChinhPhu
     */
    'type': number;
    /**
     * 
     * @type {number}
     * @memberof DapChinhPhu
     */
    'caoTrinhDinh': number;
    /**
     * 
     * @type {number}
     * @memberof DapChinhPhu
     */
    'chieuDaiTheoDinh': number;
    /**
     * 
     * @type {number}
     * @memberof DapChinhPhu
     */
    'chieuCaoLonNhat': number;
    /**
     * 
     * @type {number}
     * @memberof DapChinhPhu
     */
    'mucQuanTrong'?: number;
}
/**
 * 
 * @export
 * @interface DapTran
 */
export interface DapTran {
    /**
     * 
     * @type {string}
     * @memberof DapTran
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof DapTran
     */
    'type': number;
    /**
     * 
     * @type {number}
     * @memberof DapTran
     */
    'caoDoNguongTran': number;
    /**
     * 
     * @type {number}
     * @memberof DapTran
     */
    'khaNangXaLuThietKe': number;
    /**
     * 
     * @type {number}
     * @memberof DapTran
     */
    'khaNangXaLuKiemTra': number;
    /**
     * 
     * @type {number}
     * @memberof DapTran
     */
    'soCuaKhoangTran': number;
    /**
     * 
     * @type {string}
     * @memberof DapTran
     */
    'kichThuocKhoangTran': string;
}
/**
 * 
 * @export
 * @interface DetailDam
 */
export interface DetailDam {
    /**
     * 
     * @type {DacDiemLuuVuc}
     * @memberof DetailDam
     */
    'dacDiemLuuVuc'?: DacDiemLuuVuc;
    /**
     * 
     * @type {HoChua}
     * @memberof DetailDam
     */
    'hoChua'?: HoChua;
    /**
     * 
     * @type {Array<DapChinhPhu>}
     * @memberof DetailDam
     */
    'dapChinhPhus'?: Array<DapChinhPhu>;
    /**
     * 
     * @type {DapChinhPhu}
     * @memberof DetailDam
     */
    'dapChinh'?: DapChinhPhu;
    /**
     * 
     * @type {Array<DapTran>}
     * @memberof DetailDam
     */
    'dapTrans'?: Array<DapTran>;
    /**
     * 
     * @type {Array<CuaLayNuoc>}
     * @memberof DetailDam
     */
    'cuaLayNuocs'?: Array<CuaLayNuoc>;
    /**
     * 
     * @type {DanSoNguyHiem}
     * @memberof DetailDam
     */
    'danSoNguyHiem'?: DanSoNguyHiem;
    /**
     * 
     * @type {MucDoNghiemTrongDat}
     * @memberof DetailDam
     */
    'datNongNghiep'?: MucDoNghiemTrongDat;
    /**
     * 
     * @type {MucDoNghiemTrongDat}
     * @memberof DetailDam
     */
    'datDoThi'?: MucDoNghiemTrongDat;
    /**
     * 
     * @type {NhaMay}
     * @memberof DetailDam
     */
    'nhaMay'?: NhaMay;
    /**
     * 
     * @type {string}
     * @memberof DetailDam
     */
    'luuYGiaTriVon'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailDam
     */
    'luuYGiaTriKinhTe'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailDam
     */
    'luuYThietHaiNhanMang'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailDam
     */
    'giaTriXayLapDap'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetailDam
     */
    'giaTriXayLapNhaMay'?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailDam
     */
    'basedate': string;
    /**
     * 
     * @type {boolean}
     * @memberof DetailDam
     */
    'isFinal': boolean;
    /**
     * 
     * @type {number}
     * @memberof DetailDam
     */
    'damId': number;
    /**
     * 
     * @type {Dam}
     * @memberof DetailDam
     */
    'dam': Dam;
    /**
     * 
     * @type {StaticResponseEMBK}
     * @memberof DetailDam
     */
    'staticResponseEMBK': StaticResponseEMBK;
    /**
     * 
     * @type {StaticResponseCONC}
     * @memberof DetailDam
     */
    'staticResponseCONC': StaticResponseCONC;
    /**
     * 
     * @type {HydrologicResponse}
     * @memberof DetailDam
     */
    'hydrologicResponse': HydrologicResponse;
    /**
     * 
     * @type {SeismicResponseEMBK}
     * @memberof DetailDam
     */
    'seismicResponseEMBK': SeismicResponseEMBK;
    /**
     * 
     * @type {SeismicResponseCONC}
     * @memberof DetailDam
     */
    'seismicResponseCONC': SeismicResponseCONC;
    /**
     * 
     * @type {SafetyPlan}
     * @memberof DetailDam
     */
    'safetyPlan': SafetyPlan;
    /**
     * 
     * @type {Asset}
     * @memberof DetailDam
     */
    'mapDoSauNgapNgayNang'?: Asset;
    /**
     * 
     * @type {number}
     * @memberof DetailDam
     */
    'mapDoSauNgapNgayNangId'?: number;
    /**
     * 
     * @type {Asset}
     * @memberof DetailDam
     */
    'mapMucDoNghiemTrongNgayNang'?: Asset;
    /**
     * 
     * @type {number}
     * @memberof DetailDam
     */
    'mapMucDoNghiemTrongNgayNangId'?: number;
    /**
     * 
     * @type {Asset}
     * @memberof DetailDam
     */
    'mapThoiGianLuToiNgayNang'?: Asset;
    /**
     * 
     * @type {number}
     * @memberof DetailDam
     */
    'mapThoiGianLuToiNgayNangId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetailDam
     */
    'nguyCoRuiRo'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetailDam
     */
    'ruiRoSangLoc'?: number;
    /**
     * 
     * @type {number}
     * @memberof DetailDam
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DetailDam
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof DetailDam
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof DetailDam
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof DetailDam
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof DetailDam
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface DiaChatNenMong
 */
export interface DiaChatNenMong {
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMong
     */
    'daNutMeNhieu': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMong
     */
    'nenDatHatTho': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMong
     */
    'xuatHienCacLopYeu': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMong
     */
    'datDeXoiMon': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMong
     */
    'datDeBiHoaTan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMong
     */
    'hoChuaDeBiTonThuong': boolean;
}
/**
 * 
 * @export
 * @interface DiaChatNenMongCONC
 */
export interface DiaChatNenMongCONC {
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongCONC
     */
    'cacPhanTichNenMong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongCONC
     */
    'cacDauHieu': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongCONC
     */
    'khongCoTaiLieu': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongCONC
     */
    'quanSatDuocCacSuCo': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongCONC
     */
    'khongCungCapHeThong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongCONC
     */
    'nhieuKhopNoiHo': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongCONC
     */
    'daLoTaiDap': boolean;
}
/**
 * 
 * @export
 * @interface DiaChatNenMongSeismicCONC
 */
export interface DiaChatNenMongSeismicCONC {
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongSeismicCONC
     */
    'cacPhanTichNenMong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongSeismicCONC
     */
    'cacDauHieu': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongSeismicCONC
     */
    'khongCoHoSo': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongSeismicCONC
     */
    'quanSatDuocCacSuCo': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongSeismicCONC
     */
    'khongCoHejThongTieuThoat': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongSeismicCONC
     */
    'nhieuKhopNoiHo': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiaChatNenMongSeismicCONC
     */
    'daLoTaiDap': boolean;
}
/**
 * 
 * @export
 * @interface DienTichLuuVuc
 */
export interface DienTichLuuVuc {
    /**
     * 
     * @type {boolean}
     * @memberof DienTichLuuVuc
     */
    'nhoHon100': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DienTichLuuVuc
     */
    'trongKhoang100va300': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DienTichLuuVuc
     */
    'trongKhoang300va2500': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DienTichLuuVuc
     */
    'lonHon2500': boolean;
}
/**
 * 
 * @export
 * @interface DuBaoVaHoatDongChongLu
 */
export interface DuBaoVaHoatDongChongLu {
    /**
     * 
     * @type {boolean}
     * @memberof DuBaoVaHoatDongChongLu
     */
    'thietBiDieuTietLu': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DuBaoVaHoatDongChongLu
     */
    'bangChungVeXamThuc': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DuBaoVaHoatDongChongLu
     */
    'khaNangDuBao': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DuBaoVaHoatDongChongLu
     */
    'thieuHoacKhongDuPhuTung': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DuBaoVaHoatDongChongLu
     */
    'khongCoNguonDien': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DuBaoVaHoatDongChongLu
     */
    'khongCoQuyTac': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DuBaoVaHoatDongChongLu
     */
    'thieuCacNghienCuu': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DuBaoVaHoatDongChongLu
     */
    'khongTheDamBao': boolean;
}
/**
 * 
 * @export
 * @interface DungTichPhongLu
 */
export interface DungTichPhongLu {
    /**
     * 
     * @type {boolean}
     * @memberof DungTichPhongLu
     */
    'thapHon50': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DungTichPhongLu
     */
    'tu50Den100': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DungTichPhongLu
     */
    'gap1Den2lan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DungTichPhongLu
     */
    'gap2Den3lan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DungTichPhongLu
     */
    'gapHon3Lan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DungTichPhongLu
     */
    'khongXacDinh': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DungTichPhongLu
     */
    'damBaoAnToan': boolean;
}
/**
 * 
 * @export
 * @interface GiaTocNen
 */
export interface GiaTocNen {
    /**
     * 
     * @type {boolean}
     * @memberof GiaTocNen
     */
    'nhoHon10': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GiaTocNen
     */
    'tu10Den15': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GiaTocNen
     */
    'tu15Den25': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GiaTocNen
     */
    'tu25Den35': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GiaTocNen
     */
    'tren35': boolean;
}
/**
 * 
 * @export
 * @interface GiaTocNenCONC
 */
export interface GiaTocNenCONC {
    /**
     * 
     * @type {boolean}
     * @memberof GiaTocNenCONC
     */
    'nhoHon10': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GiaTocNenCONC
     */
    'tu10Den20': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GiaTocNenCONC
     */
    'tu20Den40': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GiaTocNenCONC
     */
    'tu40Den60': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GiaTocNenCONC
     */
    'tren60': boolean;
}
/**
 * 
 * @export
 * @interface HienTuongHoaLong
 */
export interface HienTuongHoaLong {
    /**
     * 
     * @type {boolean}
     * @memberof HienTuongHoaLong
     */
    'daThucHienPhanTichHienTuong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HienTuongHoaLong
     */
    'coDeXayRaHienTuongBienLoang': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HienTuongHoaLong
     */
    'nenCoBiHoaLong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HienTuongHoaLong
     */
    'nenDapCoBiHoaLong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HienTuongHoaLong
     */
    'dapCoDuocThietKeVoiTangLoc': boolean;
}
/**
 * 
 * @export
 * @interface HoChua
 */
export interface HoChua {
    /**
     * 
     * @type {number}
     * @memberof HoChua
     */
    'mucNuocDangBinhThuong'?: number;
    /**
     * 
     * @type {number}
     * @memberof HoChua
     */
    'mucNuocChet'?: number;
    /**
     * 
     * @type {number}
     * @memberof HoChua
     */
    'mucNuocLuThietKe'?: number;
    /**
     * 
     * @type {number}
     * @memberof HoChua
     */
    'mucNuocLuKiemTra'?: number;
    /**
     * 
     * @type {number}
     * @memberof HoChua
     */
    'dienTichMatHoUngVoiMNDBT'?: number;
    /**
     * 
     * @type {number}
     * @memberof HoChua
     */
    'dungTichToanHoChua'?: number;
    /**
     * 
     * @type {number}
     * @memberof HoChua
     */
    'dungTichHuuIch'?: number;
    /**
     * 
     * @type {number}
     * @memberof HoChua
     */
    'dungTichChet'?: number;
}
/**
 * 
 * @export
 * @interface HydrologicResponse
 */
export interface HydrologicResponse {
    /**
     * 
     * @type {LoaiDapHeSoTaiTrong}
     * @memberof HydrologicResponse
     */
    'loaiDapHeSoTaiTrongBefore': LoaiDapHeSoTaiTrong;
    /**
     * 
     * @type {LoaiDapHeSoTaiTrong}
     * @memberof HydrologicResponse
     */
    'loaiDapHeSoTaiTrongAfter': LoaiDapHeSoTaiTrong;
    /**
     * 
     * @type {string}
     * @memberof HydrologicResponse
     */
    'khuyenNghiLoaiDapHeSoTaiTrong': string;
    /**
     * 
     * @type {boolean}
     * @memberof HydrologicResponse
     */
    'percentLoaiDapHeSoTaiTrong': boolean;
    /**
     * 
     * @type {string}
     * @memberof HydrologicResponse
     */
    'canCuLoaiDapHeSoTaiTrongText': string;
    /**
     * 
     * @type {number}
     * @memberof HydrologicResponse
     */
    'canCuLoaiDapHeSoTaiTrongId': number;
    /**
     * 
     * @type {Asset}
     * @memberof HydrologicResponse
     */
    'canCuLoaiDapHeSoTaiTrong': Asset;
    /**
     * 
     * @type {LuuLuongTranThietKe}
     * @memberof HydrologicResponse
     */
    'luuLuongTranThietKeBefore': LuuLuongTranThietKe;
    /**
     * 
     * @type {LuuLuongTranThietKe}
     * @memberof HydrologicResponse
     */
    'luuLuongTranThietKeAfter': LuuLuongTranThietKe;
    /**
     * 
     * @type {string}
     * @memberof HydrologicResponse
     */
    'khuyenNghiLuuLuongTranThietKe': string;
    /**
     * 
     * @type {boolean}
     * @memberof HydrologicResponse
     */
    'percentLuuLuongTranThietKe': boolean;
    /**
     * 
     * @type {string}
     * @memberof HydrologicResponse
     */
    'canCuLuuLuongTranThietKeText': string;
    /**
     * 
     * @type {number}
     * @memberof HydrologicResponse
     */
    'canCuLuuLuongTranThietKeId': number;
    /**
     * 
     * @type {Asset}
     * @memberof HydrologicResponse
     */
    'canCuLuuLuongTranThietKe': Asset;
    /**
     * 
     * @type {DienTichLuuVuc}
     * @memberof HydrologicResponse
     */
    'dienTichLuuVucBefore': DienTichLuuVuc;
    /**
     * 
     * @type {DienTichLuuVuc}
     * @memberof HydrologicResponse
     */
    'dienTichLuuVucAfter': DienTichLuuVuc;
    /**
     * 
     * @type {string}
     * @memberof HydrologicResponse
     */
    'khuyenNghiDienTichLuuVuc': string;
    /**
     * 
     * @type {boolean}
     * @memberof HydrologicResponse
     */
    'percentDienTichLuuVuc': boolean;
    /**
     * 
     * @type {string}
     * @memberof HydrologicResponse
     */
    'canCuDienTichLuuVucText': string;
    /**
     * 
     * @type {number}
     * @memberof HydrologicResponse
     */
    'canCuDienTichLuuVucId': number;
    /**
     * 
     * @type {Asset}
     * @memberof HydrologicResponse
     */
    'canCuDienTichLuuVuc': Asset;
    /**
     * 
     * @type {DungTichPhongLu}
     * @memberof HydrologicResponse
     */
    'dungTichPhongLuBefore': DungTichPhongLu;
    /**
     * 
     * @type {DungTichPhongLu}
     * @memberof HydrologicResponse
     */
    'dungTichPhongLuAfter': DungTichPhongLu;
    /**
     * 
     * @type {string}
     * @memberof HydrologicResponse
     */
    'khuyenNghiDungTichPhongLu': string;
    /**
     * 
     * @type {boolean}
     * @memberof HydrologicResponse
     */
    'percentDungTichPhongLu': boolean;
    /**
     * 
     * @type {string}
     * @memberof HydrologicResponse
     */
    'canCuDungTichPhongLuText': string;
    /**
     * 
     * @type {number}
     * @memberof HydrologicResponse
     */
    'canCuDungTichPhongLuId': number;
    /**
     * 
     * @type {Asset}
     * @memberof HydrologicResponse
     */
    'canCuDungTichPhongLu': Asset;
    /**
     * 
     * @type {DuBaoVaHoatDongChongLu}
     * @memberof HydrologicResponse
     */
    'duBaoVaHoatDongChongLuBefore': DuBaoVaHoatDongChongLu;
    /**
     * 
     * @type {DuBaoVaHoatDongChongLu}
     * @memberof HydrologicResponse
     */
    'duBaoVaHoatDongChongLuAfter': DuBaoVaHoatDongChongLu;
    /**
     * 
     * @type {string}
     * @memberof HydrologicResponse
     */
    'khuyenNghiDuBaoVaHoatDongChongLu': string;
    /**
     * 
     * @type {boolean}
     * @memberof HydrologicResponse
     */
    'percentDuBaoVaHoatDongChongLu': boolean;
    /**
     * 
     * @type {string}
     * @memberof HydrologicResponse
     */
    'canCuDuBaoVaHoatDongChongLuText': string;
    /**
     * 
     * @type {number}
     * @memberof HydrologicResponse
     */
    'canCuDuBaoVaHoatDongChongLuId': number;
    /**
     * 
     * @type {Asset}
     * @memberof HydrologicResponse
     */
    'canCuDuBaoVaHoatDongChongLu': Asset;
    /**
     * 
     * @type {number}
     * @memberof HydrologicResponse
     */
    'totalHeSoChiuTaiBefore': number;
    /**
     * 
     * @type {number}
     * @memberof HydrologicResponse
     */
    'totalHeSoChiuTaiAfter': number;
    /**
     * 
     * @type {number}
     * @memberof HydrologicResponse
     */
    'totalHeSoPhanHoiBefore': number;
    /**
     * 
     * @type {number}
     * @memberof HydrologicResponse
     */
    'totalHeSoPhanHoiAfter': number;
}
/**
 * 
 * @export
 * @interface IdDto
 */
export interface IdDto {
    /**
     * 
     * @type {number}
     * @memberof IdDto
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface LichSuHoChuaCONC
 */
export interface LichSuHoChuaCONC {
    /**
     * 
     * @type {boolean}
     * @memberof LichSuHoChuaCONC
     */
    'duoi50': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LichSuHoChuaCONC
     */
    'tu50den75': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LichSuHoChuaCONC
     */
    'tu75den90': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LichSuHoChuaCONC
     */
    'tu90den95': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LichSuHoChuaCONC
     */
    'tu95den100': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LichSuHoChuaCONC
     */
    'tren100': boolean;
}
/**
 * 
 * @export
 * @interface LichSuHoChuaEMBK
 */
export interface LichSuHoChuaEMBK {
    /**
     * 
     * @type {boolean}
     * @memberof LichSuHoChuaEMBK
     */
    'duoi50': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LichSuHoChuaEMBK
     */
    'tu50den75': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LichSuHoChuaEMBK
     */
    'tu75den90': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LichSuHoChuaEMBK
     */
    'tu90den95': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LichSuHoChuaEMBK
     */
    'tu95den100': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LichSuHoChuaEMBK
     */
    'tren100': boolean;
}
/**
 * 
 * @export
 * @interface LoaiDapHeSoTaiTrong
 */
export interface LoaiDapHeSoTaiTrong {
    /**
     * 
     * @type {boolean}
     * @memberof LoaiDapHeSoTaiTrong
     */
    'dapVatLieuDiaPhuongVoiLoiMong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoaiDapHeSoTaiTrong
     */
    'dapVatLieuDiaPhuongVoiLoiRong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoaiDapHeSoTaiTrong
     */
    'dapBeTongTrenNenDat': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoaiDapHeSoTaiTrong
     */
    'dapDaDoBeTong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoaiDapHeSoTaiTrong
     */
    'dapVom': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoaiDapHeSoTaiTrong
     */
    'dapVatLieuDiaPhuongCoBaoVe': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoaiDapHeSoTaiTrong
     */
    'dapBeTongTrongLuc': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoaiDapHeSoTaiTrong
     */
    'dapBeTongTrongLucCoBaoVe': boolean;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponseDto
 */
export interface LoginResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface LuuLuongTranThietKe
 */
export interface LuuLuongTranThietKe {
    /**
     * 
     * @type {boolean}
     * @memberof LuuLuongTranThietKe
     */
    'thapHonDinhLu': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LuuLuongTranThietKe
     */
    'motDenHaiLan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LuuLuongTranThietKe
     */
    'haiDenBaLan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LuuLuongTranThietKe
     */
    'baDenBonLan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LuuLuongTranThietKe
     */
    'gapBonLan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LuuLuongTranThietKe
     */
    'damBaoAnToan': boolean;
}
/**
 * 
 * @export
 * @interface MucDoNghiemTrongDat
 */
export interface MucDoNghiemTrongDat {
    /**
     * 
     * @type {ThanhPhanDat}
     * @memberof MucDoNghiemTrongDat
     */
    'mucDoNghiemTrongH1': ThanhPhanDat;
    /**
     * 
     * @type {ThanhPhanDat}
     * @memberof MucDoNghiemTrongDat
     */
    'mucDoNghiemTrongH2': ThanhPhanDat;
    /**
     * 
     * @type {ThanhPhanDat}
     * @memberof MucDoNghiemTrongDat
     */
    'mucDoNghiemTrongH3': ThanhPhanDat;
    /**
     * 
     * @type {ThanhPhanDat}
     * @memberof MucDoNghiemTrongDat
     */
    'mucDoNghiemTrongH4': ThanhPhanDat;
    /**
     * 
     * @type {ThanhPhanDat}
     * @memberof MucDoNghiemTrongDat
     */
    'mucDoNghiemTrongH5': ThanhPhanDat;
    /**
     * 
     * @type {ThanhPhanDat}
     * @memberof MucDoNghiemTrongDat
     */
    'mucDoNghiemTrongH6': ThanhPhanDat;
}
/**
 * 
 * @export
 * @interface NhaMay
 */
export interface NhaMay {
    /**
     * 
     * @type {number}
     * @memberof NhaMay
     */
    'congSuatLapMay'?: number;
    /**
     * 
     * @type {number}
     * @memberof NhaMay
     */
    'soToMay'?: number;
    /**
     * 
     * @type {string}
     * @memberof NhaMay
     */
    'loaiTuabin'?: string;
    /**
     * 
     * @type {number}
     * @memberof NhaMay
     */
    'dienLuongHangNam'?: number;
}
/**
 * 
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface PermissionDefinitionConfig
 */
export interface PermissionDefinitionConfig {
    /**
     * 
     * @type {string}
     * @memberof PermissionDefinitionConfig
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionDefinitionConfig
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDefinitionConfig
     */
    'assignable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionDefinitionConfig
     */
    'internal'?: boolean;
}
/**
 * 
 * @export
 * @interface PermissionGroupDto
 */
export interface PermissionGroupDto {
    /**
     * 
     * @type {string}
     * @memberof PermissionGroupDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionGroupDto
     */
    'description': string;
    /**
     * 
     * @type {Array<PermissionDefinitionConfig>}
     * @memberof PermissionGroupDto
     */
    'permissions': Array<PermissionDefinitionConfig>;
}
/**
 * 
 * @export
 * @interface Province
 */
export interface Province {
    /**
     * 
     * @type {string}
     * @memberof Province
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Province
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof Province
     */
    'phoneCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Province
     */
    'abbr'?: string;
    /**
     * 
     * @type {Array<Dam>}
     * @memberof Province
     */
    'dams': Array<Dam>;
    /**
     * 
     * @type {Array<Customer>}
     * @memberof Province
     */
    'customers': Array<Customer>;
    /**
     * 
     * @type {number}
     * @memberof Province
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Province
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Province
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Province
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Province
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Province
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface ProvinceControllerGet200Response
 */
export interface ProvinceControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof ProvinceControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ProvinceControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ProvinceControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Province>}
     * @memberof ProvinceControllerGet200Response
     */
    'content'?: Array<Province>;
}
/**
 * 
 * @export
 * @interface ProvinceControllerGet200ResponseAllOf
 */
export interface ProvinceControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Province>}
     * @memberof ProvinceControllerGet200ResponseAllOf
     */
    'content'?: Array<Province>;
}
/**
 * 
 * @export
 * @interface QuanLyRuiRo
 */
export interface QuanLyRuiRo {
    /**
     * 
     * @type {boolean}
     * @memberof QuanLyRuiRo
     */
    'cacHanhDongKhiemKhuyetNghiemTrong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanLyRuiRo
     */
    'cacHanhDongKhongHoanToanBiLoaiBo': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanLyRuiRo
     */
    'chiNhungThieuSot': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanLyRuiRo
     */
    'khongCoHanhDongKhacPhuc': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanLyRuiRo
     */
    'baoCaoDanhGiaAnToanDinhKy': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanLyRuiRo
     */
    'baoCaoDanhGiaAnToanKhongThuongXuyen': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanLyRuiRo
     */
    'khongTrinhNopBaoCao': boolean;
}
/**
 * 
 * @export
 * @interface QuanTracDap
 */
export interface QuanTracDap {
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDap
     */
    'khongCoThietBi': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDap
     */
    'khongCoLichTrinh': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDap
     */
    'thongSoKhongDuocDanhGia': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDap
     */
    'thongSoThayDoiDotNgot': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDap
     */
    'thietBiKhongDuocTuDong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDap
     */
    'itKiemTraThucDiaItHon1LanTuan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDap
     */
    'itKiemTraThucDiaItHon1LanThang': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDap
     */
    'nangLucKiemTraKem': boolean;
}
/**
 * 
 * @export
 * @interface QuanTracDapCONC
 */
export interface QuanTracDapCONC {
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDapCONC
     */
    'khongCoThietBi': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDapCONC
     */
    'thongSoDuLieuQuanTrac': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDapCONC
     */
    'thongSoKhongDuocDanhGia': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDapCONC
     */
    'khongCoLichTrinhChinhThuc': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDapCONC
     */
    'thietBiKhongDuocTuDong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDapCONC
     */
    'itKiemTraThucDiaItHon1LanTuan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDapCONC
     */
    'itKiemTraThucDiaItHon1LanThang': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDapCONC
     */
    'nangLucKiemTraKem': boolean;
}
/**
 * 
 * @export
 * @interface QuanTracDapSeismicCONC
 */
export interface QuanTracDapSeismicCONC {
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDapSeismicCONC
     */
    'khongPhatHienDuocCacChuyenDong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDapSeismicCONC
     */
    'khongCoThongTin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuanTracDapSeismicCONC
     */
    'nangLucKiemTra': boolean;
}
/**
 * 
 * @export
 * @interface QuyTrinhQuanTrac
 */
export interface QuyTrinhQuanTrac {
    /**
     * 
     * @type {boolean}
     * @memberof QuyTrinhQuanTrac
     */
    'ketQuaTuCacHoatDong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuyTrinhQuanTrac
     */
    'phanTichVaBaoCaoBiTriHoan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuyTrinhQuanTrac
     */
    'phanTichVaBaoCaoChuaDayDu': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuyTrinhQuanTrac
     */
    'tanSuatKiemTra': boolean;
}
/**
 * 
 * @export
 * @interface RegisterCustomerDto
 */
export interface RegisterCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCustomerDto
     */
    'tempPassword'?: string;
    /**
     * 
     * @type {number}
     * @memberof RegisterCustomerDto
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RegisterCustomerDto
     */
    'provinceId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RegisterCustomerDto
     */
    'damId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterCustomerDto
     */
    'isCenter'?: boolean;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'code'?: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof Role
     */
    'users'?: Array<User>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Role
     */
    'permissions': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface RoleControllerGet200Response
 */
export interface RoleControllerGet200Response {
    /**
     * 
     * @type {number}
     * @memberof RoleControllerGet200Response
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof RoleControllerGet200Response
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof RoleControllerGet200Response
     */
    'size': number;
    /**
     * 
     * @type {Array<Role>}
     * @memberof RoleControllerGet200Response
     */
    'content'?: Array<Role>;
}
/**
 * 
 * @export
 * @interface RoleControllerGet200ResponseAllOf
 */
export interface RoleControllerGet200ResponseAllOf {
    /**
     * 
     * @type {Array<Role>}
     * @memberof RoleControllerGet200ResponseAllOf
     */
    'content'?: Array<Role>;
}
/**
 * 
 * @export
 * @interface SafetyPlan
 */
export interface SafetyPlan {
    /**
     * 
     * @type {ChuanBiKhanCap}
     * @memberof SafetyPlan
     */
    'chuanBiKhanCapBefore': ChuanBiKhanCap;
    /**
     * 
     * @type {ChuanBiKhanCap}
     * @memberof SafetyPlan
     */
    'chuanBiKhanCapAfter': ChuanBiKhanCap;
    /**
     * 
     * @type {string}
     * @memberof SafetyPlan
     */
    'khuyenNghiChuanBiKhanCap': string;
    /**
     * 
     * @type {number}
     * @memberof SafetyPlan
     */
    'percentChuanBiKhanCap': number;
    /**
     * 
     * @type {string}
     * @memberof SafetyPlan
     */
    'canCuChoDiemChuanBiKhanCapText': string;
    /**
     * 
     * @type {number}
     * @memberof SafetyPlan
     */
    'canCuChoDiemChuanBiKhanCapId': number;
    /**
     * 
     * @type {Asset}
     * @memberof SafetyPlan
     */
    'canCuChoDiemChuanBiKhanCap': Asset;
    /**
     * 
     * @type {VanHanhBaoTri}
     * @memberof SafetyPlan
     */
    'vanHanhBaoTriBefore': VanHanhBaoTri;
    /**
     * 
     * @type {VanHanhBaoTri}
     * @memberof SafetyPlan
     */
    'vanHanhBaoTriAfter': VanHanhBaoTri;
    /**
     * 
     * @type {string}
     * @memberof SafetyPlan
     */
    'khuyenNghiVanHanhBaoTri': string;
    /**
     * 
     * @type {number}
     * @memberof SafetyPlan
     */
    'percentVanHanhBaoTri': number;
    /**
     * 
     * @type {string}
     * @memberof SafetyPlan
     */
    'canCuChoDiemVanHanhBaoTriText': string;
    /**
     * 
     * @type {number}
     * @memberof SafetyPlan
     */
    'canCuChoDiemVanHanhBaoTriId': number;
    /**
     * 
     * @type {Asset}
     * @memberof SafetyPlan
     */
    'canCuChoDiemVanHanhBaoTri': Asset;
    /**
     * 
     * @type {QuyTrinhQuanTrac}
     * @memberof SafetyPlan
     */
    'quyTrinhQuanTracBefore': QuyTrinhQuanTrac;
    /**
     * 
     * @type {QuyTrinhQuanTrac}
     * @memberof SafetyPlan
     */
    'quyTrinhQuanTracAfter': QuyTrinhQuanTrac;
    /**
     * 
     * @type {string}
     * @memberof SafetyPlan
     */
    'khuyenNghiQuyTrinhQuanTrac': string;
    /**
     * 
     * @type {number}
     * @memberof SafetyPlan
     */
    'percentQuyTrinhQuanTrac': number;
    /**
     * 
     * @type {string}
     * @memberof SafetyPlan
     */
    'canCuChoDiemQuyTrinhQuanTracText': string;
    /**
     * 
     * @type {number}
     * @memberof SafetyPlan
     */
    'canCuChoDiemQuyTrinhQuanTracId': number;
    /**
     * 
     * @type {Asset}
     * @memberof SafetyPlan
     */
    'canCuChoDiemQuyTrinhQuanTrac': Asset;
    /**
     * 
     * @type {QuanLyRuiRo}
     * @memberof SafetyPlan
     */
    'quanLyRuiRoBefore': QuanLyRuiRo;
    /**
     * 
     * @type {QuanLyRuiRo}
     * @memberof SafetyPlan
     */
    'quanLyRuiRoAfter': QuanLyRuiRo;
    /**
     * 
     * @type {QuanLyRuiRo}
     * @memberof SafetyPlan
     */
    'khuyenNghiQuanLyRuiRo': QuanLyRuiRo;
    /**
     * 
     * @type {number}
     * @memberof SafetyPlan
     */
    'percentQuanLyRuiRo': number;
    /**
     * 
     * @type {string}
     * @memberof SafetyPlan
     */
    'canCuChoDiemQuanLyRuiRoText': string;
    /**
     * 
     * @type {number}
     * @memberof SafetyPlan
     */
    'canCuChoDiemQuanLyRuiRoId': number;
    /**
     * 
     * @type {Asset}
     * @memberof SafetyPlan
     */
    'canCuChoDiemQuanLyRuiRo': Asset;
    /**
     * 
     * @type {ToChucTrinhDo}
     * @memberof SafetyPlan
     */
    'toChucTrinhDoBefore': ToChucTrinhDo;
    /**
     * 
     * @type {ToChucTrinhDo}
     * @memberof SafetyPlan
     */
    'toChucTrinhDoAfter': ToChucTrinhDo;
    /**
     * 
     * @type {string}
     * @memberof SafetyPlan
     */
    'khuyenNghiToChucTrinhDo': string;
    /**
     * 
     * @type {number}
     * @memberof SafetyPlan
     */
    'percentToChucTrinhDo': number;
    /**
     * 
     * @type {string}
     * @memberof SafetyPlan
     */
    'canCuChoDiemToChucTrinhDoText': string;
    /**
     * 
     * @type {number}
     * @memberof SafetyPlan
     */
    'canCuChoDiemToChucTrinhDoId': number;
    /**
     * 
     * @type {Asset}
     * @memberof SafetyPlan
     */
    'canCuChoDiemToChucTrinhDo': Asset;
    /**
     * 
     * @type {number}
     * @memberof SafetyPlan
     */
    'totalBefore': number;
    /**
     * 
     * @type {number}
     * @memberof SafetyPlan
     */
    'totalAfter': number;
}
/**
 * 
 * @export
 * @interface SeismicResponseCONC
 */
export interface SeismicResponseCONC {
    /**
     * 
     * @type {GiaTocNenCONC}
     * @memberof SeismicResponseCONC
     */
    'giaTocNenBefore': GiaTocNenCONC;
    /**
     * 
     * @type {GiaTocNenCONC}
     * @memberof SeismicResponseCONC
     */
    'giaTocNenAfter': GiaTocNenCONC;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseCONC
     */
    'khuyenNghiGiaTocNen': string;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseCONC
     */
    'percentGiaTocNen': number;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseCONC
     */
    'canCuChoDiemGiaTocNenText': string;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseCONC
     */
    'canCuChoDiemGiaTocNenId': number;
    /**
     * 
     * @type {Asset}
     * @memberof SeismicResponseCONC
     */
    'canCuChoDiemGiaTocNen': Asset;
    /**
     * 
     * @type {TinhTrangHienTaiDapSeismicCONC}
     * @memberof SeismicResponseCONC
     */
    'tinhTrangHienTaiDapSeismicCONCBefore': TinhTrangHienTaiDapSeismicCONC;
    /**
     * 
     * @type {TinhTrangHienTaiDapSeismicCONC}
     * @memberof SeismicResponseCONC
     */
    'tinhTrangHienTaiDapSeismicCONCAfter': TinhTrangHienTaiDapSeismicCONC;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseCONC
     */
    'khuyenNghiTinhTrangHienTaiDapSeismicCONC': string;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseCONC
     */
    'percentTinhTrangHienTaiDapSeismicCONC': string;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseCONC
     */
    'canCuTinhTrangHienTaiDapSeismicCONCText': string;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseCONC
     */
    'canCuTinhTrangHienTaiDapSeismicCONCId': number;
    /**
     * 
     * @type {Asset}
     * @memberof SeismicResponseCONC
     */
    'canCuTinhTrangHienTaiDapSeismicCONC': Asset;
    /**
     * 
     * @type {ThietKeThiCongSeismicCONC}
     * @memberof SeismicResponseCONC
     */
    'thietKeThiCongSeismicCONCBefore': ThietKeThiCongSeismicCONC;
    /**
     * 
     * @type {ThietKeThiCongSeismicCONC}
     * @memberof SeismicResponseCONC
     */
    'thietKeThiCongSeismicCONCAfter': ThietKeThiCongSeismicCONC;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseCONC
     */
    'khuyenNghiThietKeThiCongSeismic': string;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseCONC
     */
    'percentThietKeThiCongSeismic': number;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseCONC
     */
    'canCuThietKeThiCongSeismicText': string;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseCONC
     */
    'canCuThietKeThiCongSeismicId': number;
    /**
     * 
     * @type {Asset}
     * @memberof SeismicResponseCONC
     */
    'canCuThietKeThiCongSeismic': Asset;
    /**
     * 
     * @type {DiaChatNenMongSeismicCONC}
     * @memberof SeismicResponseCONC
     */
    'diaChatNenMongSeismicCONCBefore': DiaChatNenMongSeismicCONC;
    /**
     * 
     * @type {DiaChatNenMongSeismicCONC}
     * @memberof SeismicResponseCONC
     */
    'diaChatNenMongSeismicCONCAfter': DiaChatNenMongSeismicCONC;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseCONC
     */
    'khuyenNghiDiaChatNenMongSeismicCONC': string;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseCONC
     */
    'percentDiaChatNenMongSeismicCONC': number;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseCONC
     */
    'canCuDiaChatNenMongSeismicCONCText': string;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseCONC
     */
    'canCuDiaChatNenMongSeismicCONCId': number;
    /**
     * 
     * @type {Asset}
     * @memberof SeismicResponseCONC
     */
    'canCuDiaChatNenMongSeismicCONC': Asset;
    /**
     * 
     * @type {QuanTracDapSeismicCONC}
     * @memberof SeismicResponseCONC
     */
    'quanTracDapSeismicCONCBefore': QuanTracDapSeismicCONC;
    /**
     * 
     * @type {QuanTracDapSeismicCONC}
     * @memberof SeismicResponseCONC
     */
    'quanTracDapSeismicCONCAfter': QuanTracDapSeismicCONC;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseCONC
     */
    'khuyenNghiQuanTracDapSeismicCONC': string;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseCONC
     */
    'percentQuanTracDapSeismicCONC': number;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseCONC
     */
    'canCuQuanTracDapSeismicCONCText': string;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseCONC
     */
    'canCuQuanTracDapSeismicCONCId': number;
    /**
     * 
     * @type {Asset}
     * @memberof SeismicResponseCONC
     */
    'canCuQuanTracDapSeismicCONC': Asset;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseCONC
     */
    'totalHeSoPhanHoi1Before': number;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseCONC
     */
    'totalHeSoPhanHoi1After': number;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseCONC
     */
    'totalHeSoPhanHoi2Before': number;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseCONC
     */
    'totalHeSoPhanHoi2After': number;
}
/**
 * 
 * @export
 * @interface SeismicResponseEMBK
 */
export interface SeismicResponseEMBK {
    /**
     * 
     * @type {boolean}
     * @memberof SeismicResponseEMBK
     */
    'thucHienPhanTichHoaLong': boolean;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseEMBK
     */
    'loaiDapDat': string;
    /**
     * 
     * @type {boolean}
     * @memberof SeismicResponseEMBK
     */
    'dapCoBaoGomCacThietKeChongChiu': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SeismicResponseEMBK
     */
    'coBatKyVanDeVeHoaLongCuaDat': boolean;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseEMBK
     */
    'loaiNen': string;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseEMBK
     */
    'doCaoAnToanThietKe': number;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseEMBK
     */
    'chieuCaoDapTrenLongSong': number;
    /**
     * 
     * @type {GiaTocNen}
     * @memberof SeismicResponseEMBK
     */
    'giaTocNenBefore': GiaTocNen;
    /**
     * 
     * @type {GiaTocNen}
     * @memberof SeismicResponseEMBK
     */
    'giaTocNenAfter': GiaTocNen;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseEMBK
     */
    'khuyenNghiGiaTocNen': string;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseEMBK
     */
    'percentGiaTocNen': number;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseEMBK
     */
    'canCuGiaTocNenText': string;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseEMBK
     */
    'canGiaTocNenId': number;
    /**
     * 
     * @type {Asset}
     * @memberof SeismicResponseEMBK
     */
    'canCuGiaTocNen': Asset;
    /**
     * 
     * @type {HienTuongHoaLong}
     * @memberof SeismicResponseEMBK
     */
    'hienTuongHoaLongBefore': HienTuongHoaLong;
    /**
     * 
     * @type {HienTuongHoaLong}
     * @memberof SeismicResponseEMBK
     */
    'hienTuongHoaLongAfter': HienTuongHoaLong;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseEMBK
     */
    'khuyenNghiHienTuongHoaLong': string;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseEMBK
     */
    'percentHienTuongHoaLong': string;
    /**
     * 
     * @type {string}
     * @memberof SeismicResponseEMBK
     */
    'canCuHienTuongHoaLongText': string;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseEMBK
     */
    'canCuHienTuongHoaLongId': number;
    /**
     * 
     * @type {Asset}
     * @memberof SeismicResponseEMBK
     */
    'canCuHienTuongHoaLong': Asset;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseEMBK
     */
    'totalHeSoChiuTaiBefore': number;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseEMBK
     */
    'totalHeSoChiuTaiAfter': number;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseEMBK
     */
    'totalHeSoPhanHoiBefore': number;
    /**
     * 
     * @type {number}
     * @memberof SeismicResponseEMBK
     */
    'totalHeSoPhanHoiAfter': number;
}
/**
 * 
 * @export
 * @interface StaticResponseCONC
 */
export interface StaticResponseCONC {
    /**
     * 
     * @type {TinhTrangHienTaiDapBeTong}
     * @memberof StaticResponseCONC
     */
    'tinhTrangHienTaiDapBeTongBefore': TinhTrangHienTaiDapBeTong;
    /**
     * 
     * @type {TinhTrangHienTaiDapBeTong}
     * @memberof StaticResponseCONC
     */
    'tinhTrangHienTaiDapBeTongAfter': TinhTrangHienTaiDapBeTong;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseCONC
     */
    'khuyenNghiTinhTrangHienTaiDapBeTong': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseCONC
     */
    'percentTinhTrangHienTaiDapBeTong': number;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseCONC
     */
    'canCuTinhTrangHienTaiDapBeTongText': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseCONC
     */
    'canCuTinhTrangHienTaiDapBeTongId': number;
    /**
     * 
     * @type {Asset}
     * @memberof StaticResponseCONC
     */
    'canCuTinhTrangHienTaiDapBeTong': Asset;
    /**
     * 
     * @type {LichSuHoChuaCONC}
     * @memberof StaticResponseCONC
     */
    'lichSuTichNuocHoChuaBefore': LichSuHoChuaCONC;
    /**
     * 
     * @type {LichSuHoChuaCONC}
     * @memberof StaticResponseCONC
     */
    'lichSuTichNuocHoChuaAfter': LichSuHoChuaCONC;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseCONC
     */
    'khuyenNghiLichSuNuocHoChua': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseCONC
     */
    'percentLichSuNuocHoChua': number;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseCONC
     */
    'canCuLichSuNuocHoChuaText': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseCONC
     */
    'canCuLichSuNuocHoChuaId': number;
    /**
     * 
     * @type {Asset}
     * @memberof StaticResponseCONC
     */
    'canCuLichSuNuocHoChua': Asset;
    /**
     * 
     * @type {ThietKeThiCong}
     * @memberof StaticResponseCONC
     */
    'thietKeThiCongBefore': ThietKeThiCong;
    /**
     * 
     * @type {ThietKeThiCong}
     * @memberof StaticResponseCONC
     */
    'thietKeThiCongAfter': ThietKeThiCong;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseCONC
     */
    'khuyenNghiThietKeThiCong': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseCONC
     */
    'percentThietKeThiCong': number;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseCONC
     */
    'canCuThietKeThiCongText': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseCONC
     */
    'canCuThietKeThiCongId': number;
    /**
     * 
     * @type {Asset}
     * @memberof StaticResponseCONC
     */
    'canCuThietKeThiCong': Asset;
    /**
     * 
     * @type {DiaChatNenMongCONC}
     * @memberof StaticResponseCONC
     */
    'diaChatNenMongCONCBefore': DiaChatNenMongCONC;
    /**
     * 
     * @type {DiaChatNenMongCONC}
     * @memberof StaticResponseCONC
     */
    'diaChatNenMongCONCAfter': DiaChatNenMongCONC;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseCONC
     */
    'khuyenNghiDiaChatNenMongCONC': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseCONC
     */
    'percentDiaChatNenMongCONC': number;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseCONC
     */
    'canCuDiaChatNenMongCONCText': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseCONC
     */
    'canCuDiaChatNenMongCONCId': number;
    /**
     * 
     * @type {Asset}
     * @memberof StaticResponseCONC
     */
    'canCuDiaChatNenMongCONC': Asset;
    /**
     * 
     * @type {QuanTracDapCONC}
     * @memberof StaticResponseCONC
     */
    'quanTracDapCONCBefore': QuanTracDapCONC;
    /**
     * 
     * @type {QuanTracDapCONC}
     * @memberof StaticResponseCONC
     */
    'quanTracDapCONCAfter': QuanTracDapCONC;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseCONC
     */
    'khuyenNghiQuanTracDapCONC': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseCONC
     */
    'percentQuanTracDapCONC': number;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseCONC
     */
    'canCuQuanTracDapCONCText': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseCONC
     */
    'canCuQuanTracDapCONCId': number;
    /**
     * 
     * @type {Asset}
     * @memberof StaticResponseCONC
     */
    'canCuQuanTracDapCONC': Asset;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseCONC
     */
    'totalBefore': number;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseCONC
     */
    'totalAfter': number;
}
/**
 * 
 * @export
 * @interface StaticResponseEMBK
 */
export interface StaticResponseEMBK {
    /**
     * 
     * @type {boolean}
     * @memberof StaticResponseEMBK
     */
    'coDiXuyenDap': boolean;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'truongHop': number;
    /**
     * 
     * @type {CongTrinhXa}
     * @memberof StaticResponseEMBK
     */
    'congTrinhXaBefore': CongTrinhXa;
    /**
     * 
     * @type {CongTrinhXa}
     * @memberof StaticResponseEMBK
     */
    'congTrinhXaAfter': CongTrinhXa;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseEMBK
     */
    'khuyenNghiCongTrinhXa': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'percentCongTrinhXa': number;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseEMBK
     */
    'canCuCongTrinhXaText': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'canCuCongTrinhXaId': number;
    /**
     * 
     * @type {Asset}
     * @memberof StaticResponseEMBK
     */
    'canCuCongTrinhXa': Asset;
    /**
     * 
     * @type {LichSuHoChuaEMBK}
     * @memberof StaticResponseEMBK
     */
    'lichSuTichHoChuaBefore': LichSuHoChuaEMBK;
    /**
     * 
     * @type {LichSuHoChuaEMBK}
     * @memberof StaticResponseEMBK
     */
    'lichSuTichHoChuaAfter': LichSuHoChuaEMBK;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseEMBK
     */
    'khuyenNghiLichSuTichHoChua': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'percentLichSuTichHoChua': number;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseEMBK
     */
    'canCuLichSuTichHoChuaText': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'canCuLichSuTichHoChuaId': number;
    /**
     * 
     * @type {Asset}
     * @memberof StaticResponseEMBK
     */
    'canCuLichSuTichHoChua': Asset;
    /**
     * 
     * @type {ThamVaBienDang}
     * @memberof StaticResponseEMBK
     */
    'thamVaBienDangBefore': ThamVaBienDang;
    /**
     * 
     * @type {ThamVaBienDang}
     * @memberof StaticResponseEMBK
     */
    'thamVaBienDangAfter': ThamVaBienDang;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseEMBK
     */
    'khuyenNghiThamVaBienDang': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'percentThamVaBienDang': number;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseEMBK
     */
    'canCuThamVaBienDangText': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'canCuThamVaBienDangId': number;
    /**
     * 
     * @type {Asset}
     * @memberof StaticResponseEMBK
     */
    'canCuThamVaBienDang': Asset;
    /**
     * 
     * @type {ThietKeXayDungDap}
     * @memberof StaticResponseEMBK
     */
    'thietKeXayDungDapBefore': ThietKeXayDungDap;
    /**
     * 
     * @type {ThietKeXayDungDap}
     * @memberof StaticResponseEMBK
     */
    'thietKeXayDungDapAfter': ThietKeXayDungDap;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseEMBK
     */
    'khuyenNghiThietKeXayDungDap': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'percentThietKeXayDungDap': number;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseEMBK
     */
    'canCuThietKeXayDungDapText': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'canCuThietKeXayDungDapId': number;
    /**
     * 
     * @type {Asset}
     * @memberof StaticResponseEMBK
     */
    'canCuThietKeXayDungDap': Asset;
    /**
     * 
     * @type {DiaChatNenMong}
     * @memberof StaticResponseEMBK
     */
    'diaChatNenMongBefore': DiaChatNenMong;
    /**
     * 
     * @type {DiaChatNenMong}
     * @memberof StaticResponseEMBK
     */
    'diaChatNenMongAfter': DiaChatNenMong;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseEMBK
     */
    'khuyenNghiDiaChatNenMong': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'percentDiaChatNenMong': number;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseEMBK
     */
    'canCuDiaChatNenMongText': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'canCuDiaChatNenMongId': number;
    /**
     * 
     * @type {Asset}
     * @memberof StaticResponseEMBK
     */
    'canCuDiaChatNenMong': Asset;
    /**
     * 
     * @type {QuanTracDap}
     * @memberof StaticResponseEMBK
     */
    'quanTracDapBefore': QuanTracDap;
    /**
     * 
     * @type {QuanTracDap}
     * @memberof StaticResponseEMBK
     */
    'quanTracDapAfter': QuanTracDap;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseEMBK
     */
    'khuyenNghiQuanTracDap': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'percentQuanTracDap': number;
    /**
     * 
     * @type {string}
     * @memberof StaticResponseEMBK
     */
    'canCuQuanTracDapText': string;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'canCuQuanTracDapId': number;
    /**
     * 
     * @type {Asset}
     * @memberof StaticResponseEMBK
     */
    'canCuQuanTracDap': Asset;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'totalBefore': number;
    /**
     * 
     * @type {number}
     * @memberof StaticResponseEMBK
     */
    'totalAfter': number;
}
/**
 * 
 * @export
 * @interface SuccessResponseDto
 */
export interface SuccessResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponseDto
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface ThamVaBienDang
 */
export interface ThamVaBienDang {
    /**
     * 
     * @type {boolean}
     * @memberof ThamVaBienDang
     */
    'thamGayVoVunNho': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThamVaBienDang
     */
    'thamGiaTang': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThamVaBienDang
     */
    'luongThamLon': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThamVaBienDang
     */
    'chuyenDichMaiDoc': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThamVaBienDang
     */
    'xuatHienHoSut': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThamVaBienDang
     */
    'thoatNuocCHanDapKem': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThamVaBienDang
     */
    'dinhDapTrongTinhTrangKem': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThamVaBienDang
     */
    'apSuatPhunCaoBatThuong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThamVaBienDang
     */
    'thieuHutBaoVeMaiDoc': boolean;
}
/**
 * 
 * @export
 * @interface ThanhPhanDat
 */
export interface ThanhPhanDat {
    /**
     * 
     * @type {number}
     * @memberof ThanhPhanDat
     */
    'dienTichDat'?: number;
    /**
     * 
     * @type {number}
     * @memberof ThanhPhanDat
     */
    'diemThamPhan'?: number;
    /**
     * 
     * @type {number}
     * @memberof ThanhPhanDat
     */
    'chiSoDat'?: number;
}
/**
 * 
 * @export
 * @interface ThietKeThiCong
 */
export interface ThietKeThiCong {
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeThiCong
     */
    'phanTichDap': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeThiCong
     */
    'khongCoTaiLieuPhanTich': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeThiCong
     */
    'khongCoHeThongTieuThoatNuoc': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeThiCong
     */
    'suDungBeTongCuongDoThap': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeThiCong
     */
    'khongKiemSoatBeTong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeThiCong
     */
    'thietBiVanHanhLu': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeThiCong
     */
    'khongCoLopLoc': boolean;
}
/**
 * 
 * @export
 * @interface ThietKeThiCongSeismicCONC
 */
export interface ThietKeThiCongSeismicCONC {
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeThiCongSeismicCONC
     */
    'phanTichDap': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeThiCongSeismicCONC
     */
    'khongCoHoSoPhanTich': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeThiCongSeismicCONC
     */
    'suDungBeTongCuongDoThap': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeThiCongSeismicCONC
     */
    'khongCoCacKhopNoi': boolean;
}
/**
 * 
 * @export
 * @interface ThietKeXayDungDap
 */
export interface ThietKeXayDungDap {
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeXayDungDap
     */
    'khongCoTangLoc': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeXayDungDap
     */
    'khongCoBienPhapXuLy': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeXayDungDap
     */
    'khongCoCacLopTieuThoatNuoc': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeXayDungDap
     */
    'ketCauLoiDeBiXoiMon': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeXayDungDap
     */
    'nenMongKhongCoKhaNangChongTham': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeXayDungDap
     */
    'dapXayDungKem': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ThietKeXayDungDap
     */
    'khongCoKhaNangThaoCanHo': boolean;
}
/**
 * 
 * @export
 * @interface TinhTrangHienTaiDapBeTong
 */
export interface TinhTrangHienTaiDapBeTong {
    /**
     * 
     * @type {boolean}
     * @memberof TinhTrangHienTaiDapBeTong
     */
    'thamVuotMucQuaLopBeTong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TinhTrangHienTaiDapBeTong
     */
    'bangChungVeCacThietHai': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TinhTrangHienTaiDapBeTong
     */
    'nutHoacHuHongVuotMuc': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TinhTrangHienTaiDapBeTong
     */
    'dauHieuVeCacBienDang': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TinhTrangHienTaiDapBeTong
     */
    'tinhTrangAmUot': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TinhTrangHienTaiDapBeTong
     */
    'nuocXaTrongHanhLang': boolean;
}
/**
 * 
 * @export
 * @interface TinhTrangHienTaiDapSeismicCONC
 */
export interface TinhTrangHienTaiDapSeismicCONC {
    /**
     * 
     * @type {boolean}
     * @memberof TinhTrangHienTaiDapSeismicCONC
     */
    'thamVuotMucQuaLopBeTong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TinhTrangHienTaiDapSeismicCONC
     */
    'bangChungThietHaiKhac': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TinhTrangHienTaiDapSeismicCONC
     */
    'nutHoacHuHongVuotMuc': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TinhTrangHienTaiDapSeismicCONC
     */
    'dauHieuVeCacBienDang': boolean;
}
/**
 * 
 * @export
 * @interface ToChucTrinhDo
 */
export interface ToChucTrinhDo {
    /**
     * 
     * @type {boolean}
     * @memberof ToChucTrinhDo
     */
    'chuDapTrinhDo': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ToChucTrinhDo
     */
    'chuDapChuaDuTrinhDo': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ToChucTrinhDo
     */
    'khongCoDuCoCau': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ToChucTrinhDo
     */
    'thieuCacHoatDong': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ToChucTrinhDo
     */
    'cacKhoaDaoTao': boolean;
}
/**
 * 
 * @export
 * @interface UpdateCompanyDto
 */
export interface UpdateCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'taxCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDto
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface UpdateCustomerDto
 */
export interface UpdateCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerDto
     */
    'tempPassword'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomerDto
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomerDto
     */
    'provinceId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomerDto
     */
    'damId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCustomerDto
     */
    'isCenter'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateDamDto
 */
export interface UpdateDamDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDamDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDamDto
     */
    'provinceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDamDto
     */
    'companyId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateDetailDamDto
 */
export interface UpdateDetailDamDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateDetailDamDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDetailDamDto
     */
    'damId'?: number;
    /**
     * 
     * @type {DacDiemLuuVuc}
     * @memberof UpdateDetailDamDto
     */
    'dacDiemLuuVuc'?: DacDiemLuuVuc;
    /**
     * 
     * @type {HoChua}
     * @memberof UpdateDetailDamDto
     */
    'hoChua'?: HoChua;
    /**
     * 
     * @type {Array<DapChinhPhu>}
     * @memberof UpdateDetailDamDto
     */
    'dapChinhPhus'?: Array<DapChinhPhu>;
    /**
     * 
     * @type {Array<DapTran>}
     * @memberof UpdateDetailDamDto
     */
    'dapTrans'?: Array<DapTran>;
    /**
     * 
     * @type {DapChinhPhu}
     * @memberof UpdateDetailDamDto
     */
    'dapChinh'?: DapChinhPhu;
    /**
     * 
     * @type {Array<CuaLayNuoc>}
     * @memberof UpdateDetailDamDto
     */
    'cuaLayNuocs'?: Array<CuaLayNuoc>;
    /**
     * 
     * @type {NhaMay}
     * @memberof UpdateDetailDamDto
     */
    'nhaMay'?: NhaMay;
    /**
     * 
     * @type {DanSoNguyHiem}
     * @memberof UpdateDetailDamDto
     */
    'danSoNguyHiem'?: DanSoNguyHiem;
    /**
     * 
     * @type {string}
     * @memberof UpdateDetailDamDto
     */
    'basedate'?: string;
    /**
     * 
     * @type {MucDoNghiemTrongDat}
     * @memberof UpdateDetailDamDto
     */
    'datNongNghiep'?: MucDoNghiemTrongDat;
    /**
     * 
     * @type {MucDoNghiemTrongDat}
     * @memberof UpdateDetailDamDto
     */
    'datDoThi'?: MucDoNghiemTrongDat;
    /**
     * 
     * @type {string}
     * @memberof UpdateDetailDamDto
     */
    'luuYGiaTriVon'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDetailDamDto
     */
    'luuYGiaTriKinhTe'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDetailDamDto
     */
    'luuYThietHaiNhanMang'?: string;
    /**
     * 
     * @type {StaticResponseEMBK}
     * @memberof UpdateDetailDamDto
     */
    'staticResponseEMBK'?: StaticResponseEMBK;
    /**
     * 
     * @type {StaticResponseCONC}
     * @memberof UpdateDetailDamDto
     */
    'staticResponseCONC'?: StaticResponseCONC;
    /**
     * 
     * @type {HydrologicResponse}
     * @memberof UpdateDetailDamDto
     */
    'hydrologicResponse'?: HydrologicResponse;
    /**
     * 
     * @type {SeismicResponseEMBK}
     * @memberof UpdateDetailDamDto
     */
    'seismicResponseEMBK'?: SeismicResponseEMBK;
    /**
     * 
     * @type {SeismicResponseCONC}
     * @memberof UpdateDetailDamDto
     */
    'seismicResponseCONC'?: SeismicResponseCONC;
    /**
     * 
     * @type {SafetyPlan}
     * @memberof UpdateDetailDamDto
     */
    'safetyPlan'?: SafetyPlan;
    /**
     * 
     * @type {number}
     * @memberof UpdateDetailDamDto
     */
    'giaTriXayLapDap'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDetailDamDto
     */
    'giaTriXayLapNhaMay'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDetailDamDto
     */
    'mapDoSauNgapNgayNangId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDetailDamDto
     */
    'mapMucDoNghiemTrongNgayNangId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDetailDamDto
     */
    'mapThoiGianLuToiNgayNangId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDetailDamDto
     */
    'nguyCoRuiRo'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateDetailDamDto
     */
    'ruiRoSangLoc'?: number;
}
/**
 * 
 * @export
 * @interface UpdateMigrationDto
 */
export interface UpdateMigrationDto {
    /**
     * 
     * @type {Array<UpdateDetailDamDto>}
     * @memberof UpdateMigrationDto
     */
    'payload': Array<UpdateDetailDamDto>;
}
/**
 * 
 * @export
 * @interface UpdateRoleDto
 */
export interface UpdateRoleDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleDto
     */
    'code'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRoleDto
     */
    'permissions'?: Array<string>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'identifier': string;
    /**
     * 
     * @type {Array<AuthenticationMethod>}
     * @memberof User
     */
    'authenticationMethods': Array<AuthenticationMethod>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'verified': boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastLogin': string | null;
    /**
     * 
     * @type {Array<Role>}
     * @memberof User
     */
    'roles': Array<Role>;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'createdByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastModifiedOnDate': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'lastModifiedByUserId': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'deletedAt': string;
}
/**
 * 
 * @export
 * @interface VanHanhBaoTri
 */
export interface VanHanhBaoTri {
    /**
     * 
     * @type {boolean}
     * @memberof VanHanhBaoTri
     */
    'taiLieuHuongDan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VanHanhBaoTri
     */
    'chiCoMotSoThanhPhan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VanHanhBaoTri
     */
    'khongCoTaiLieu': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VanHanhBaoTri
     */
    'cayCoiMocTren': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VanHanhBaoTri
     */
    'xuatHienNhieuOChuot': boolean;
}
/**
 * 
 * @export
 * @interface VerifyTokenDto
 */
export interface VerifyTokenDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyTokenDto
     */
    'token': string;
}

/**
 * ApiMigrationApi - axios parameter creator
 * @export
 */
export const ApiMigrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UpdateMigrationDto} updateMigrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMigrationControllerUpdate: async (updateMigrationDto: UpdateMigrationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMigrationDto' is not null or undefined
            assertParamExists('apiMigrationControllerUpdate', 'updateMigrationDto', updateMigrationDto)
            const localVarPath = `/api-migration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMigrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiMigrationApi - functional programming interface
 * @export
 */
export const ApiMigrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiMigrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UpdateMigrationDto} updateMigrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMigrationControllerUpdate(updateMigrationDto: UpdateMigrationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMigrationControllerUpdate(updateMigrationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiMigrationApi - factory interface
 * @export
 */
export const ApiMigrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiMigrationApiFp(configuration)
    return {
        /**
         * 
         * @param {UpdateMigrationDto} updateMigrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMigrationControllerUpdate(updateMigrationDto: UpdateMigrationDto, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.apiMigrationControllerUpdate(updateMigrationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiMigrationApi - object-oriented interface
 * @export
 * @class ApiMigrationApi
 * @extends {BaseAPI}
 */
export class ApiMigrationApi extends BaseAPI {
    /**
     * 
     * @param {UpdateMigrationDto} updateMigrationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiMigrationApi
     */
    public apiMigrationControllerUpdate(updateMigrationDto: UpdateMigrationDto, options?: AxiosRequestConfig) {
        return ApiMigrationApiFp(this.configuration).apiMigrationControllerUpdate(updateMigrationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFolderDto} createFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerCreateFolder: async (createFolderDto: CreateFolderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFolderDto' is not null or undefined
            assertParamExists('assetControllerCreateFolder', 'createFolderDto', createFolderDto)
            const localVarPath = `/assets/create-folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFolderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetControllerDelete', 'id', id)
            const localVarPath = `/assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerDiscover: async (folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('assetControllerDiscover', 'folderId', folderId)
            const localVarPath = `/assets/{folderId}/discover`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assetControllerGetById', 'id', id)
            const localVarPath = `/assets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {number} [assetFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerUploadFile: async (file: File, assetFolderId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('assetControllerUploadFile', 'file', file)
            const localVarPath = `/assets/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (assetFolderId !== undefined) { 
                localVarFormParams.append('assetFolderId', assetFolderId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFolderDto} createFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerCreateFolder(createFolderDto: CreateFolderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerCreateFolder(createFolderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerDiscover(folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Asset>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerDiscover(folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {number} [assetFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetControllerUploadFile(file: File, assetFolderId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetControllerUploadFile(file, assetFolderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFolderDto} createFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerCreateFolder(createFolderDto: CreateFolderDto, options?: any): AxiosPromise<object> {
            return localVarFp.assetControllerCreateFolder(createFolderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.assetControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerDiscover(folderId: string, options?: any): AxiosPromise<Array<Asset>> {
            return localVarFp.assetControllerDiscover(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerGetById(id: string, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {number} [assetFolderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetControllerUploadFile(file: File, assetFolderId?: number, options?: any): AxiosPromise<Asset> {
            return localVarFp.assetControllerUploadFile(file, assetFolderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI {
    /**
     * 
     * @param {CreateFolderDto} createFolderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerCreateFolder(createFolderDto: CreateFolderDto, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerCreateFolder(createFolderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerDelete(id: string, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerDiscover(folderId: string, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerDiscover(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerGetById(id: string, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {number} [assetFolderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetsApi
     */
    public assetControllerUploadFile(file: File, assetFolderId?: number, options?: AxiosRequestConfig) {
        return AssetsApiFp(this.configuration).assetControllerUploadFile(file, assetFolderId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminLogin: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerAdminLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/admin/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/admin/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCustomerLogin: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerCustomerLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/customer/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCustomerMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/customer/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterCustomerDto} registerCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegisterCustomerAccount: async (registerCustomerDto: RegisterCustomerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerCustomerDto' is not null or undefined
            assertParamExists('authControllerRegisterCustomerAccount', 'registerCustomerDto', registerCustomerDto)
            const localVarPath = `/auth/customer/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyTokenDto} verifyTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyToken: async (verifyTokenDto: VerifyTokenDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyTokenDto' is not null or undefined
            assertParamExists('authControllerVerifyToken', 'verifyTokenDto', verifyTokenDto)
            const localVarPath = `/auth/verify-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAdminLogin(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAdminLogin(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAdminMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Administrator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAdminMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCustomerLogin(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCustomerLogin(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCustomerMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCustomerMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterCustomerDto} registerCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRegisterCustomerAccount(registerCustomerDto: RegisterCustomerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRegisterCustomerAccount(registerCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyTokenDto} verifyTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerVerifyToken(verifyTokenDto: VerifyTokenDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerVerifyToken(verifyTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminLogin(loginDto: LoginDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerAdminLogin(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminMe(options?: any): AxiosPromise<Administrator> {
            return localVarFp.authControllerAdminMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCustomerLogin(loginDto: LoginDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerCustomerLogin(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCustomerMe(options?: any): AxiosPromise<Customer> {
            return localVarFp.authControllerCustomerMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterCustomerDto} registerCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegisterCustomerAccount(registerCustomerDto: RegisterCustomerDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerRegisterCustomerAccount(registerCustomerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyTokenDto} verifyTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifyToken(verifyTokenDto: VerifyTokenDto, options?: any): AxiosPromise<SuccessResponseDto> {
            return localVarFp.authControllerVerifyToken(verifyTokenDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Login
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerAdminLogin(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerAdminLogin(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerAdminMe(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerAdminMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCustomerLogin(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerCustomerLogin(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCustomerMe(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerCustomerMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterCustomerDto} registerCustomerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRegisterCustomerAccount(registerCustomerDto: RegisterCustomerDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRegisterCustomerAccount(registerCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyTokenDto} verifyTokenDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerVerifyToken(verifyTokenDto: VerifyTokenDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerVerifyToken(verifyTokenDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerCreate: async (createCompanyDto: CreateCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyDto' is not null or undefined
            assertParamExists('companyControllerCreate', 'createCompanyDto', createCompanyDto)
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerDelete', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerGet: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerGetById', 'id', id)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdate: async (id: string, updateCompanyDto: UpdateCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyControllerUpdate', 'id', id)
            // verify required parameter 'updateCompanyDto' is not null or undefined
            assertParamExists('companyControllerUpdate', 'updateCompanyDto', updateCompanyDto)
            const localVarPath = `/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerCreate(createCompanyDto: CreateCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerCreate(createCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGet(page, size, sort, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerUpdate(id: string, updateCompanyDto: UpdateCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerUpdate(id, updateCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCompanyDto} createCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerCreate(createCompanyDto: CreateCompanyDto, options?: any): AxiosPromise<object> {
            return localVarFp.companyControllerCreate(createCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.companyControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: any): AxiosPromise<CompanyControllerGet200Response> {
            return localVarFp.companyControllerGet(page, size, sort, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerGetById(id: string, options?: any): AxiosPromise<Company> {
            return localVarFp.companyControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCompanyDto} updateCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerUpdate(id: string, updateCompanyDto: UpdateCompanyDto, options?: any): AxiosPromise<object> {
            return localVarFp.companyControllerUpdate(id, updateCompanyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * 
     * @param {CreateCompanyDto} createCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companyControllerCreate(createCompanyDto: CreateCompanyDto, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companyControllerCreate(createCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companyControllerDelete(id: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companyControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companyControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companyControllerGet(page, size, sort, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companyControllerGetById(id: string, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companyControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateCompanyDto} updateCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public companyControllerUpdate(id: string, updateCompanyDto: UpdateCompanyDto, options?: AxiosRequestConfig) {
        return CompaniesApiFp(this.configuration).companyControllerUpdate(id, updateCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RegisterCustomerDto} registerCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerCreate: async (registerCustomerDto: RegisterCustomerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerCustomerDto' is not null or undefined
            assertParamExists('customerControllerCreate', 'registerCustomerDto', registerCustomerDto)
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customerControllerDelete', 'id', id)
            const localVarPath = `/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [provinceId] 
         * @param {number} [damId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGet: async (companyId?: number, provinceId?: number, damId?: number, page?: number, size?: number, sort?: string, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (provinceId !== undefined) {
                localVarQueryParameter['provinceId'] = provinceId;
            }

            if (damId !== undefined) {
                localVarQueryParameter['damId'] = damId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customerControllerGetById', 'id', id)
            const localVarPath = `/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCustomerDto} updateCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerUpdate: async (id: string, updateCustomerDto: UpdateCustomerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customerControllerUpdate', 'id', id)
            // verify required parameter 'updateCustomerDto' is not null or undefined
            assertParamExists('customerControllerUpdate', 'updateCustomerDto', updateCustomerDto)
            const localVarPath = `/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RegisterCustomerDto} registerCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerCreate(registerCustomerDto: RegisterCustomerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerCreate(registerCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [provinceId] 
         * @param {number} [damId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGet(companyId?: number, provinceId?: number, damId?: number, page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGet(companyId, provinceId, damId, page, size, sort, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCustomerDto} updateCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerUpdate(id: string, updateCustomerDto: UpdateCustomerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerControllerUpdate(id, updateCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * 
         * @param {RegisterCustomerDto} registerCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerCreate(registerCustomerDto: RegisterCustomerDto, options?: any): AxiosPromise<void> {
            return localVarFp.customerControllerCreate(registerCustomerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [provinceId] 
         * @param {number} [damId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGet(companyId?: number, provinceId?: number, damId?: number, page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: any): AxiosPromise<CustomerControllerGet200Response> {
            return localVarFp.customerControllerGet(companyId, provinceId, damId, page, size, sort, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetById(id: string, options?: any): AxiosPromise<Customer> {
            return localVarFp.customerControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCustomerDto} updateCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerUpdate(id: string, updateCustomerDto: UpdateCustomerDto, options?: any): AxiosPromise<object> {
            return localVarFp.customerControllerUpdate(id, updateCustomerDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * 
     * @param {RegisterCustomerDto} registerCustomerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerCreate(registerCustomerDto: RegisterCustomerDto, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerCreate(registerCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerDelete(id: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [provinceId] 
     * @param {number} [damId] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGet(companyId?: number, provinceId?: number, damId?: number, page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGet(companyId, provinceId, damId, page, size, sort, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerGetById(id: string, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateCustomerDto} updateCustomerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customerControllerUpdate(id: string, updateCustomerDto: UpdateCustomerDto, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customerControllerUpdate(id, updateCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DamsApi - axios parameter creator
 * @export
 */
export const DamsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {AddAssetsDamDto} addAssetsDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerAddAsset: async (id: string, addAssetsDamDto: AddAssetsDamDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('damControllerAddAsset', 'id', id)
            // verify required parameter 'addAssetsDamDto' is not null or undefined
            assertParamExists('damControllerAddAsset', 'addAssetsDamDto', addAssetsDamDto)
            const localVarPath = `/dams/{id}/asset`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addAssetsDamDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDamDto} createDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerCreate: async (createDamDto: CreateDamDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDamDto' is not null or undefined
            assertParamExists('damControllerCreate', 'createDamDto', createDamDto)
            const localVarPath = `/dams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDamDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('damControllerDelete', 'id', id)
            const localVarPath = `/dams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [companyId] 
         * @param {number} [provinceId] 
         * @param {'RUIRO' | 'NGUYCO'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerGet: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, companyId?: number, provinceId?: number, type?: 'RUIRO' | 'NGUYCO', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (provinceId !== undefined) {
                localVarQueryParameter['provinceId'] = provinceId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DamSearchFilter} damSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerGetAllDamSortNguyCoRuiRo: async (damSearchFilter: DamSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'damSearchFilter' is not null or undefined
            assertParamExists('damControllerGetAllDamSortNguyCoRuiRo', 'damSearchFilter', damSearchFilter)
            const localVarPath = `/dams/get-all-dam-sort-nguy-co-rui-ro`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(damSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('damControllerGetById', 'id', id)
            const localVarPath = `/dams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} provinceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerGetByProvinceId: async (provinceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provinceId' is not null or undefined
            assertParamExists('damControllerGetByProvinceId', 'provinceId', provinceId)
            const localVarPath = `/dams/{provinceId}/province`
                .replace(`{${"provinceId"}}`, encodeURIComponent(String(provinceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDamDto} updateDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerUpdate: async (id: string, updateDamDto: UpdateDamDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('damControllerUpdate', 'id', id)
            // verify required parameter 'updateDamDto' is not null or undefined
            assertParamExists('damControllerUpdate', 'updateDamDto', updateDamDto)
            const localVarPath = `/dams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDamDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DamsApi - functional programming interface
 * @export
 */
export const DamsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DamsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {AddAssetsDamDto} addAssetsDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async damControllerAddAsset(id: string, addAssetsDamDto: AddAssetsDamDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.damControllerAddAsset(id, addAssetsDamDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateDamDto} createDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async damControllerCreate(createDamDto: CreateDamDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.damControllerCreate(createDamDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async damControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.damControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [companyId] 
         * @param {number} [provinceId] 
         * @param {'RUIRO' | 'NGUYCO'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async damControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, companyId?: number, provinceId?: number, type?: 'RUIRO' | 'NGUYCO', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DamControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.damControllerGet(page, size, sort, fullTextSearch, companyId, provinceId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DamSearchFilter} damSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async damControllerGetAllDamSortNguyCoRuiRo(damSearchFilter: DamSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Dam>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.damControllerGetAllDamSortNguyCoRuiRo(damSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async damControllerGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.damControllerGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} provinceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async damControllerGetByProvinceId(provinceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Dam>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.damControllerGetByProvinceId(provinceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDamDto} updateDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async damControllerUpdate(id: string, updateDamDto: UpdateDamDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.damControllerUpdate(id, updateDamDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DamsApi - factory interface
 * @export
 */
export const DamsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DamsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {AddAssetsDamDto} addAssetsDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerAddAsset(id: string, addAssetsDamDto: AddAssetsDamDto, options?: any): AxiosPromise<object> {
            return localVarFp.damControllerAddAsset(id, addAssetsDamDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDamDto} createDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerCreate(createDamDto: CreateDamDto, options?: any): AxiosPromise<object> {
            return localVarFp.damControllerCreate(createDamDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.damControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {number} [companyId] 
         * @param {number} [provinceId] 
         * @param {'RUIRO' | 'NGUYCO'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, companyId?: number, provinceId?: number, type?: 'RUIRO' | 'NGUYCO', options?: any): AxiosPromise<DamControllerGet200Response> {
            return localVarFp.damControllerGet(page, size, sort, fullTextSearch, companyId, provinceId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DamSearchFilter} damSearchFilter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerGetAllDamSortNguyCoRuiRo(damSearchFilter: DamSearchFilter, options?: any): AxiosPromise<Array<Dam>> {
            return localVarFp.damControllerGetAllDamSortNguyCoRuiRo(damSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerGetById(id: string, options?: any): AxiosPromise<Dam> {
            return localVarFp.damControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} provinceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerGetByProvinceId(provinceId: string, options?: any): AxiosPromise<Array<Dam>> {
            return localVarFp.damControllerGetByProvinceId(provinceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDamDto} updateDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        damControllerUpdate(id: string, updateDamDto: UpdateDamDto, options?: any): AxiosPromise<object> {
            return localVarFp.damControllerUpdate(id, updateDamDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DamsApi - object-oriented interface
 * @export
 * @class DamsApi
 * @extends {BaseAPI}
 */
export class DamsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {AddAssetsDamDto} addAssetsDamDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamsApi
     */
    public damControllerAddAsset(id: string, addAssetsDamDto: AddAssetsDamDto, options?: AxiosRequestConfig) {
        return DamsApiFp(this.configuration).damControllerAddAsset(id, addAssetsDamDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDamDto} createDamDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamsApi
     */
    public damControllerCreate(createDamDto: CreateDamDto, options?: AxiosRequestConfig) {
        return DamsApiFp(this.configuration).damControllerCreate(createDamDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamsApi
     */
    public damControllerDelete(id: string, options?: AxiosRequestConfig) {
        return DamsApiFp(this.configuration).damControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {number} [companyId] 
     * @param {number} [provinceId] 
     * @param {'RUIRO' | 'NGUYCO'} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamsApi
     */
    public damControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, companyId?: number, provinceId?: number, type?: 'RUIRO' | 'NGUYCO', options?: AxiosRequestConfig) {
        return DamsApiFp(this.configuration).damControllerGet(page, size, sort, fullTextSearch, companyId, provinceId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DamSearchFilter} damSearchFilter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamsApi
     */
    public damControllerGetAllDamSortNguyCoRuiRo(damSearchFilter: DamSearchFilter, options?: AxiosRequestConfig) {
        return DamsApiFp(this.configuration).damControllerGetAllDamSortNguyCoRuiRo(damSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamsApi
     */
    public damControllerGetById(id: string, options?: AxiosRequestConfig) {
        return DamsApiFp(this.configuration).damControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} provinceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamsApi
     */
    public damControllerGetByProvinceId(provinceId: string, options?: AxiosRequestConfig) {
        return DamsApiFp(this.configuration).damControllerGetByProvinceId(provinceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDamDto} updateDamDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DamsApi
     */
    public damControllerUpdate(id: string, updateDamDto: UpdateDamDto, options?: AxiosRequestConfig) {
        return DamsApiFp(this.configuration).damControllerUpdate(id, updateDamDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DetailDamsApi - axios parameter creator
 * @export
 */
export const DetailDamsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDetailDamDto} createDetailDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerCreate: async (createDetailDamDto: CreateDetailDamDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDetailDamDto' is not null or undefined
            assertParamExists('detailDamControllerCreate', 'createDetailDamDto', createDetailDamDto)
            const localVarPath = `/detail-dams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDetailDamDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/detail-dams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} damId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerGetBeforeDetailDam: async (damId: string, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'damId' is not null or undefined
            assertParamExists('detailDamControllerGetBeforeDetailDam', 'damId', damId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('detailDamControllerGetBeforeDetailDam', 'date', date)
            const localVarPath = `/detail-dams/dam/{damId}/before/{date}`
                .replace(`{${"damId"}}`, encodeURIComponent(String(damId)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} damId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerGetByDamId: async (damId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'damId' is not null or undefined
            assertParamExists('detailDamControllerGetByDamId', 'damId', damId)
            const localVarPath = `/detail-dams/dam/{damId}`
                .replace(`{${"damId"}}`, encodeURIComponent(String(damId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('detailDamControllerGetById', 'id', id)
            const localVarPath = `/detail-dams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerLock: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('detailDamControllerLock', 'id', id)
            const localVarPath = `/detail-dams/{id}/lock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerUnlock: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('detailDamControllerUnlock', 'id', id)
            const localVarPath = `/detail-dams/{id}/unlock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDetailDamDto} updateDetailDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerUpdate: async (id: string, updateDetailDamDto: UpdateDetailDamDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('detailDamControllerUpdate', 'id', id)
            // verify required parameter 'updateDetailDamDto' is not null or undefined
            assertParamExists('detailDamControllerUpdate', 'updateDetailDamDto', updateDetailDamDto)
            const localVarPath = `/detail-dams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDetailDamDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DetailDamsApi - functional programming interface
 * @export
 */
export const DetailDamsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DetailDamsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDetailDamDto} createDetailDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailDamControllerCreate(createDetailDamDto: CreateDetailDamDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailDam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailDamControllerCreate(createDetailDamDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailDamControllerGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetailDam>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailDamControllerGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} damId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailDamControllerGetBeforeDetailDam(damId: string, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailDam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailDamControllerGetBeforeDetailDam(damId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} damId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailDamControllerGetByDamId(damId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetailDam>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailDamControllerGetByDamId(damId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailDamControllerGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailDam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailDamControllerGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailDamControllerLock(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailDamControllerLock(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailDamControllerUnlock(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailDamControllerUnlock(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDetailDamDto} updateDetailDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detailDamControllerUpdate(id: string, updateDetailDamDto: UpdateDetailDamDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailDam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detailDamControllerUpdate(id, updateDetailDamDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DetailDamsApi - factory interface
 * @export
 */
export const DetailDamsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DetailDamsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDetailDamDto} createDetailDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerCreate(createDetailDamDto: CreateDetailDamDto, options?: any): AxiosPromise<DetailDam> {
            return localVarFp.detailDamControllerCreate(createDetailDamDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerGetAll(options?: any): AxiosPromise<Array<DetailDam>> {
            return localVarFp.detailDamControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} damId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerGetBeforeDetailDam(damId: string, date: string, options?: any): AxiosPromise<DetailDam> {
            return localVarFp.detailDamControllerGetBeforeDetailDam(damId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} damId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerGetByDamId(damId: string, options?: any): AxiosPromise<Array<DetailDam>> {
            return localVarFp.detailDamControllerGetByDamId(damId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerGetById(id: string, options?: any): AxiosPromise<DetailDam> {
            return localVarFp.detailDamControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerLock(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.detailDamControllerLock(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerUnlock(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.detailDamControllerUnlock(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDetailDamDto} updateDetailDamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailDamControllerUpdate(id: string, updateDetailDamDto: UpdateDetailDamDto, options?: any): AxiosPromise<DetailDam> {
            return localVarFp.detailDamControllerUpdate(id, updateDetailDamDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DetailDamsApi - object-oriented interface
 * @export
 * @class DetailDamsApi
 * @extends {BaseAPI}
 */
export class DetailDamsApi extends BaseAPI {
    /**
     * 
     * @param {CreateDetailDamDto} createDetailDamDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetailDamsApi
     */
    public detailDamControllerCreate(createDetailDamDto: CreateDetailDamDto, options?: AxiosRequestConfig) {
        return DetailDamsApiFp(this.configuration).detailDamControllerCreate(createDetailDamDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetailDamsApi
     */
    public detailDamControllerGetAll(options?: AxiosRequestConfig) {
        return DetailDamsApiFp(this.configuration).detailDamControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} damId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetailDamsApi
     */
    public detailDamControllerGetBeforeDetailDam(damId: string, date: string, options?: AxiosRequestConfig) {
        return DetailDamsApiFp(this.configuration).detailDamControllerGetBeforeDetailDam(damId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} damId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetailDamsApi
     */
    public detailDamControllerGetByDamId(damId: string, options?: AxiosRequestConfig) {
        return DetailDamsApiFp(this.configuration).detailDamControllerGetByDamId(damId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetailDamsApi
     */
    public detailDamControllerGetById(id: string, options?: AxiosRequestConfig) {
        return DetailDamsApiFp(this.configuration).detailDamControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetailDamsApi
     */
    public detailDamControllerLock(id: string, options?: AxiosRequestConfig) {
        return DetailDamsApiFp(this.configuration).detailDamControllerLock(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetailDamsApi
     */
    public detailDamControllerUnlock(id: string, options?: AxiosRequestConfig) {
        return DetailDamsApiFp(this.configuration).detailDamControllerUnlock(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDetailDamDto} updateDetailDamDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetailDamsApi
     */
    public detailDamControllerUpdate(id: string, updateDetailDamDto: UpdateDetailDamDto, options?: AxiosRequestConfig) {
        return DetailDamsApiFp(this.configuration).detailDamControllerUpdate(id, updateDetailDamDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PermissionsApi - axios parameter creator
 * @export
 */
export const PermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PermissionsApi - functional programming interface
 * @export
 */
export const PermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionGroupDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PermissionsApi - factory interface
 * @export
 */
export const PermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PermissionsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionControllerGet(options?: any): AxiosPromise<Array<PermissionGroupDto>> {
            return localVarFp.permissionControllerGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PermissionsApi - object-oriented interface
 * @export
 * @class PermissionsApi
 * @extends {BaseAPI}
 */
export class PermissionsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PermissionsApi
     */
    public permissionControllerGet(options?: AxiosRequestConfig) {
        return PermissionsApiFp(this.configuration).permissionControllerGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProvincesApi - axios parameter creator
 * @export
 */
export const ProvincesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        provinceControllerGet: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/provinces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProvincesApi - functional programming interface
 * @export
 */
export const ProvincesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProvincesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async provinceControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProvinceControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.provinceControllerGet(page, size, sort, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProvincesApi - factory interface
 * @export
 */
export const ProvincesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProvincesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        provinceControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: any): AxiosPromise<ProvinceControllerGet200Response> {
            return localVarFp.provinceControllerGet(page, size, sort, fullTextSearch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProvincesApi - object-oriented interface
 * @export
 * @class ProvincesApi
 * @extends {BaseAPI}
 */
export class ProvincesApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvincesApi
     */
    public provinceControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return ProvincesApiFp(this.configuration).provinceControllerGet(page, size, sort, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesApi - axios parameter creator
 * @export
 */
export const RolesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreate: async (createRoleDto: CreateRoleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoleDto' is not null or undefined
            assertParamExists('roleControllerCreate', 'createRoleDto', createRoleDto)
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGet: async (page?: number, size?: number, sort?: string, fullTextSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['fullTextSearch'] = fullTextSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roleControllerGetById', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleDto} updateRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerUpdate: async (id: string, updateRoleDto: UpdateRoleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('roleControllerUpdate', 'id', id)
            // verify required parameter 'updateRoleDto' is not null or undefined
            assertParamExists('roleControllerUpdate', 'updateRoleDto', updateRoleDto)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRoleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerCreate(createRoleDto: CreateRoleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerCreate(createRoleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleControllerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerGet(page, size, sort, fullTextSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerGetById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Role>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerGetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleDto} updateRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roleControllerUpdate(id: string, updateRoleDto: UpdateRoleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roleControllerUpdate(id, updateRoleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRoleDto} createRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerCreate(createRoleDto: CreateRoleDto, options?: any): AxiosPromise<Role> {
            return localVarFp.roleControllerCreate(createRoleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [fullTextSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: any): AxiosPromise<RoleControllerGet200Response> {
            return localVarFp.roleControllerGet(page, size, sort, fullTextSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerGetById(id: string, options?: any): AxiosPromise<Role> {
            return localVarFp.roleControllerGetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRoleDto} updateRoleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roleControllerUpdate(id: string, updateRoleDto: UpdateRoleDto, options?: any): AxiosPromise<object> {
            return localVarFp.roleControllerUpdate(id, updateRoleDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @param {CreateRoleDto} createRoleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerCreate(createRoleDto: CreateRoleDto, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerCreate(createRoleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [fullTextSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerGet(page?: number, size?: number, sort?: string, fullTextSearch?: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerGet(page, size, sort, fullTextSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerGetById(id: string, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerGetById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateRoleDto} updateRoleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public roleControllerUpdate(id: string, updateRoleDto: UpdateRoleDto, options?: AxiosRequestConfig) {
        return RolesApiFp(this.configuration).roleControllerUpdate(id, updateRoleDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGet(options?: any): AxiosPromise<void> {
            return localVarFp.userControllerGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerGet(options).then((request) => request(this.axios, this.basePath));
    }
}


