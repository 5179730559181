import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateEditCustomer from "./customer/CreateEditCustomer";
import { SuspenseWrapper } from "../../components/loading/SuspenseWrap";

const Admin = React.lazy(() => import("./index"));
const ListCustomer = React.lazy(() => import("./customer/ListCustomer"));
const ListRole = React.lazy(() => import("./role/ListRole"));
const ListCompany = React.lazy(() => import("./company/ListCompany"));
const CreateRole = React.lazy(() => import("./role/CreateEditRole"));
const CreateEditCompany = React.lazy(() => import("./company/CreateEditCompany"));
const ListDam = React.lazy(() => import("./dam/ListDam"));
const CreateEditDam = React.lazy(() => import("./dam/CreateEditDam"));
const SignInAdmin = React.lazy(() => import("./auth/SignInAdmin"));
const MigrationUpdate = React.lazy(() => import("./migration-update"));
const DasboardAdmin = React.lazy(() => import("./dasboard"));

export const AdminRoutes = () => (
  <Routes>
    <Route path="signin" element={<SignInAdmin />} />
    <Route path="" element={<Admin />}>
      <Route path="update" element={<SuspenseWrapper component={<MigrationUpdate />} />} />
      <Route path="dasboard" element={<SuspenseWrapper component={<DasboardAdmin />} />} />
      <Route path="customer">
        <Route path="" element={<SuspenseWrapper component={<ListCustomer />} />} />
        <Route path="create" element={<SuspenseWrapper component={<CreateEditCustomer />} />} />
        <Route path="detail/:id" element={<SuspenseWrapper component={<CreateEditCustomer />} />} />
      </Route>
      <Route path="company">
        <Route path="" element={<SuspenseWrapper component={<ListCompany />} />} />
        <Route path="create" element={<SuspenseWrapper component={<CreateEditCompany />} />} />
        <Route path="detail/:id" element={<SuspenseWrapper component={<CreateEditCompany />} />} />
      </Route>
      <Route path="roles">
        <Route path="" element={<SuspenseWrapper component={<ListRole />} />} />
        <Route path="create" element={<SuspenseWrapper component={<CreateRole />} />} />
        <Route path="detail/:id" element={<SuspenseWrapper component={<CreateRole />} />} />
      </Route>
      <Route path="dam">
        <Route path="" element={<SuspenseWrapper component={<ListDam />} />} />
        <Route path="create" element={<SuspenseWrapper component={<CreateEditDam />} />} />
        <Route path="detail/:id" element={<SuspenseWrapper component={<CreateEditDam />} />} />
      </Route>
    </Route>
  </Routes>
);
