import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { axiosInstance, detailDapApi, provinceApi } from "../apis";
import { DetailDam, UpdateDetailDamDto } from "../apis/client-axios";
import { message } from "antd";

export enum DamType {
  VLDP = "VLDP",
  BE_TONG = "BE_TONG",
  HON_HOP = "HON_HOP",
}

export interface DetaiDamState {
  detailDamChoose?: DetailDam;
  detailDamChooseBefore?: DetailDam;
  detailDams?: DetailDam[];
  isUpdating?: boolean;
  riskScreening: RiskScreeningState;
  damType?: DamType;
  loadFactor: number;
}

export interface RiskScreeningState {
  hoSoRuiRoBefore?: HoSoRuiRoState;
  hoSoRuiRoAfter?: HoSoRuiRoState;
}

export interface HoSoRuiRoState {
  totalFI: number;
  totalRI: number;
}

const initialState: DetaiDamState = {
  detailDamChoose: undefined,
  detailDams: undefined,
  riskScreening: {
    hoSoRuiRoBefore: undefined,
    hoSoRuiRoAfter: undefined,
  },
  loadFactor: 0,
  // damType:
};

export const getDetailDapBeforeById = createAsyncThunk(
  "detail-dams/getById/before",
  async (payload: { damId: string; date: string }) => {
    const date = payload.date.slice(0, 10);
    const response = await detailDapApi.detailDamControllerGetBeforeDetailDam(payload.damId, date);
    return response.data;
  }
);

export const getDetailDapById = createAsyncThunk("detail-dams/getById", async (id: string) => {
  const response = await detailDapApi.detailDamControllerGetById(id);
  return response.data;
});

export const getDetailDapByDamId = createAsyncThunk("detail-dams/getByDamId", async (id: string) => {
  const response = await detailDapApi.detailDamControllerGetByDamId(id);
  return response.data;
});

export const updateDetailDam = createAsyncThunk(
  "detail-dams/update",
  async (payload: { id: string; updateDetailDamDto: UpdateDetailDamDto }, { dispatch }) => {
    const response = await detailDapApi.detailDamControllerUpdate(payload.id, payload.updateDetailDamDto);
    dispatch(getDetailDapById(payload.id));
    return response.data;
  }
);

export const detailDamSlice = createSlice({
  name: "detail-dams",
  initialState,
  reducers: {
    setHoSoRuiRoBefore: (state, action: PayloadAction<HoSoRuiRoState>) => {
      state.riskScreening.hoSoRuiRoBefore = action.payload;
    },
    setHoSoRuiRoAfter: (state, action: PayloadAction<HoSoRuiRoState>) => {
      state.riskScreening.hoSoRuiRoAfter = action.payload;
    },
    setLoadFactor: (state, action: PayloadAction<number>) => {
      state.loadFactor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDetailDapById.fulfilled, (state, action) => {
      state.detailDamChoose = action.payload;
      // Set Loại đập
    });
    builder.addCase(getDetailDapBeforeById.fulfilled, (state, action) => {
      state.detailDamChooseBefore = action.payload;
    });
    builder.addCase(getDetailDapByDamId.fulfilled, (state, action) => {
      state.detailDams = action.payload;
    });
    builder.addCase(updateDetailDam.pending, (state, action) => {
      state.isUpdating = true;
    });
    builder.addCase(updateDetailDam.fulfilled, (state, action) => {
      state.isUpdating = false;
      message.success("Cập nhật thành công");
    });
    builder.addCase(updateDetailDam.rejected, (state, action) => {
      message.error(action.error.message);
      state.isUpdating = false;
    });
  },
});
export const { setHoSoRuiRoBefore, setHoSoRuiRoAfter } = detailDamSlice.actions;
export const selectDetailDamSlice = (state: RootState) => state.detailDam;
export default detailDamSlice.reducer;
