import { Form, FormProps, Radio } from "antd";
import { Children, ReactNode, useState } from "react";
type LayoutType = Parameters<typeof Form>[0]["layout"];

function FormWrap(props: FormProps & { children: ReactNode }) {
  const [formLayout, setFormLayout] = useState<LayoutType>(props.layout || "horizontal");

  const formItemLayout =
    formLayout === "horizontal"
      ? { labelCol: { span: props.labelCol?.span ?? 8 }, wrapperCol: { span: props.wrapperCol?.span ?? 16 } }
      : null;

  return (
    <Form
      {...formItemLayout}
      layout={formLayout}
      form={props.form}
      labelWrap
      // initialValues={{ layout: formLayout }}
      // style={{ maxWidth: formLayout === "inline" ? "none" : 600 }}
      {...props}
    >
      {props.children}
    </Form>
  );
}

export default FormWrap;
