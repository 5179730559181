import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Card, Col, Form, FormItemProps, Input, Radio, Row, Space, message } from "antd";
import Title from "antd/es/typography/Title";
import FormWrap from "../../../../components/FormWrap";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import SaveButton from "../../../../components/buttons/SaveButton";
import BackButton from "../../../../components/buttons/BackButton";
import { companyApi, customerApi, damApi } from "../../../../apis";
import { CreateCompanyDto, RegisterCustomerDto, UpdateCustomerDto } from "../../../../apis/client-axios";
import { useIntl } from "react-intl";
import { AxiosError } from "axios";
import { isNil } from "lodash";
import SelectWrap from "../../../../components/inputs/SelectWrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

const enum UserOption {
  Dam = "Công trình",
  Province = "Tỉnh",
  Company = "Công ty",
  Center = "Trung ương",
}

const CreateEditCustomer = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();

  const { provinces } = useSelector((state: RootState) => state.province);
  const [option, setOption] = useState<UserOption>(UserOption.Dam);

  const createMutation = useMutation((payload: RegisterCustomerDto) => customerApi.customerControllerCreate(payload), {
    onSuccess: ({ data }) => {
      message.success(intl.formatMessage({ id: "common.createSuccess" }));
      navigate("/admin/customer");
    },
    onError: (error: AxiosError) => {
      message.error((error.response?.data as any)?.message);
    },
  });
  const { data: companiesData } = useQuery(["getCompanies"], () => companyApi.companyControllerGet());
  const { data: damsData } = useQuery(["getDams"], () => damApi.damControllerGet());

  const updateMutation = useMutation(
    (payload: UpdateCustomerDto) => customerApi.customerControllerUpdate(id as string, payload),
    {
      onSuccess: ({ data }) => {
        message.success(intl.formatMessage({ id: "common.updateSuccess" }));
      },
      onError: (error: AxiosError) => {
        message.error((error.response?.data as any)?.message);
      },
    }
  );

  const { data: company } = useQuery(
    ["getCompanyById", { id }],
    () => customerApi.customerControllerGetById(id as string),
    {
      enabled: !isNil(id),
      onSuccess(data) {
        form.setFieldsValue(data.data);
        if (data.data.damId) {
          setOption(UserOption.Dam);
        } else if (data.data.companyId) {
          setOption(UserOption.Company);
        } else if (data.data.provinceId) {
          setOption(UserOption.Province);
        } else if (data.data.isCenter) {
          setOption(UserOption.Center);
        }
      },
    }
  );

  const n = (key: keyof RegisterCustomerDto) => {
    return key;
  };

  const onFinish = (values: RegisterCustomerDto) => {
    values.damId = option == UserOption.Dam ? values.damId : null;
    values.provinceId = option == UserOption.Province ? values.provinceId : null;
    values.companyId = option == UserOption.Company ? values.companyId : null;
    values.isCenter = option == UserOption.Center;

    if (id) {
      updateMutation.mutate(values);
    } else {
      createMutation.mutate(values);
    }
  };

  return (
    <Card title={id ? <h4>Chi tiết người dùng</h4> : <h4>Tạo mới người dùng</h4>}>
      <Row>
        <Col span={18}>
          <FormWrap form={form} onFinish={onFinish}>
            <Form.Item label={"Tên đăng nhập"} name={n("emailAddress")} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label={"Họ và tên"} name={n("fullName")}>
              <Input />
            </Form.Item>
            <Form.Item label={"Loại"}>
              <Radio.Group value={option} onChange={(e) => setOption(e.target.value)}>
                <Radio value={UserOption.Dam}>Công trình</Radio>
                <Radio value={UserOption.Province}>Tỉnh</Radio>
                <Radio value={UserOption.Company}>Công ty</Radio>
                <Radio value={UserOption.Center}>Trung ương</Radio>
              </Radio.Group>
            </Form.Item>
            {option == UserOption.Province && (
              <Form.Item label={UserOption.Province} name={n("provinceId")} rules={[{ required: true }]}>
                <SelectWrap
                  options={provinces?.map((p) => {
                    return {
                      value: p.id,
                      label: p.name,
                    };
                  })}
                />
              </Form.Item>
            )}
            {option == UserOption.Company && (
              <Form.Item label={UserOption.Company} name={n("companyId")} rules={[{ required: true }]}>
                <SelectWrap
                  options={companiesData?.data.content?.map((c) => {
                    return {
                      value: c.id,
                      label: c.name,
                    };
                  })}
                />
              </Form.Item>
            )}
            {option == UserOption.Dam && (
              <Form.Item label={UserOption.Dam} name={n("damId")} rules={[{ required: true }]}>
                <SelectWrap
                  options={damsData?.data.content?.map((c) => {
                    return {
                      value: c.id,
                      label: c.name,
                    };
                  })}
                />
              </Form.Item>
            )}
            <Col offset={8}>
              <Space>
                <BackButton onClick={() => navigate("/admin/customer")} />
                <SaveButton loading={createMutation.isLoading || updateMutation.isLoading} />
              </Space>
            </Col>
          </FormWrap>
        </Col>
      </Row>
    </Card>
  );
};

export default CreateEditCustomer;
